import requset from '@/utils/requset';

// 产品组查询
export function ProductFindPageData(data){
  return requset({
    url:'/Product/FindPageData',
    method:'post',
    data
  })
}

// 下拉选择产品
export function GetProductDrop(params){
  return requset({
    url:'/Product/GetProductDrop',
    method:'get',
    params
  })
}

// 新建产品
export function ProductAdd(data){
  return requset({
    url:'/Product/Add',
    method:'post',
    data
  })
}

// 修改产品
export function ProductUpdate(data){
  return requset({
    url:'/Product/Update',
    method:'post',
    data
  })
}

// 产品的 启用/停用 状态
export function ProductSetValid(params){
  return requset({
    url:'/Product/SetValid',
    method:'post',
    params
  })
}

// 删除产品
export function ProductSetDelete(params){
  return requset({
    url:'/Product/SetDelete',
    method:'post',
    params
  })
}

// 下拉选择经销商
export function GetDealerDrop(params){
  return requset({
    url:'/Dealer/GetDealerDrop',
    method:'get',
    params
  })
}

// 抽奖活动查询
export function PrizeActivityFindPageData(data){
  return requset({
    url:'/PrizeActivity/FindPageData',
    method:'post',
    data
  })
}
// 抽奖活动新增
export function PrizeActivityAdd(data){
  return requset({
    url:'/PrizeActivity/Add',
    method:'post',
    data
  })
}
// 抽奖活动修改
export function PrizeActivityUpdate(data){
  return requset({
    url:'/PrizeActivity/Update',
    method:'post',
    data
  })
}
// 抽奖活动 启用/停用
export function PrizeActivitySetValid(params){
  return requset({
    url:'/PrizeActivity/SetValid',
    method:'post',
    params
  })
}
// 抽奖活动删除
export function PrizeActivitySetDelete(params){
  return requset({
    url:'/PrizeActivity/SetDelete',
    method:'post',
    params
  })
}

// 中奖规则查询
export function PrizeRuleFindPageData(data){
  return requset({
    url:'/PrizeRule/FindPageData',
    method:'post',
    data
  })
}
// 中奖规则新增
export function PrizeRuleAdd(data){
  return requset({
    url:'/PrizeRule/Add',
    method:'post',
    data
  })
}
// 中奖规则修改
export function PrizeRuleUpdate(data){
  return requset({
    url:'/PrizeRule/Update',
    method:'post',
    data
  })
}

// 下拉中奖规则
export function GetPrizeRuleDrop(params){
  return requset({
    url:'/PrizeRule/GetPrizeRuleDrop',
    method:'get',
    params
  })
}

// 中奖规则 启用/停用
export function PrizeRuleSetValid(params){
  return requset({
    url:'/PrizeRule/SetValid',
    method:'post',
    params
  })
}
// 中奖规则删除
export function PrizeRuleSetDelete(params){
  return requset({
    url:'/PrizeRule/SetDelete',
    method:'post',
    params
  })
}

