/** 中奖规则 */ 

<template>
  <!-- 组件主盒子 -->
  <div class="stbox">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>中奖规则</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询 -->
    <el-form class="user-search" @submit.native.prevent>
      <el-form-item label="关键字：">
        <el-input type="text" size="small" placeholder="活动名称"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search">查询</el-button>
      </el-form-item>
    </el-form>
    <!--表格展示 -->

    <el-row class="plusdata marbottom22">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible1 = true">新增</el-button>
    </el-row>
    <el-table class="marbottom22" :data="tableData" height="620" style="width: 100%; " :default-sort = "{prop: 'date', order: 'descending'}" stripe border
    >
      <el-table-column align="" type="index" width="60" label="#"></el-table-column>
      <el-table-column prop="Name" label="规则名称" width="180"></el-table-column>
      <el-table-column prop="RuleType" label="规则类型" width="120">
        <template slot-scope="{row}">
          <span v-if="row.RuleType === 1">概率</span>
        </template>
      </el-table-column>
      <el-table-column prop="PrizeProbabilityList[0].Probability" label="概率一" width="100"></el-table-column>
      <el-table-column prop="PrizeProbabilityList[0].Amount" label="金额一(元)" width="120"></el-table-column>
      <el-table-column prop="PrizeProbabilityList[1].Probability" label="概率二" width="100"></el-table-column>
      <el-table-column prop="PrizeProbabilityList[1].Amount" label="金额二(元)" width="120"></el-table-column>
      <el-table-column prop="PrizeProbabilityList[2].Probability" label="概率三" width="100"></el-table-column>
      <el-table-column prop="PrizeProbabilityList[2].Amount" label="金额三(元)" width="120"></el-table-column>
      <el-table-column prop="PrizeProbabilityList[3].Probability" label="概率四" width="100"></el-table-column>
      <el-table-column prop="PrizeProbabilityList[3].Amount" label="金额四(元)" width="120"></el-table-column>
      <el-table-column prop="PrizeProbabilityList[4].Probability" label="概率五" width="100"></el-table-column>
      <el-table-column prop="PrizeProbabilityList[4].Amount" label="金额五(元)" width="120"></el-table-column>
      <el-table-column prop="Remark" label="备注"></el-table-column>
      <el-table-column align="center" label="操作" width="300" fixed="right">
        <template slot-scope="{row}">
          <div class="caozuo">
            <el-button size="mini" type="primary" >开启活动</el-button>
            <!-- <el-button v-if="row.IsValid === 1" size="mini" type="warning" @click="setValid(row)">关闭活动</el-button> -->
            <el-button size="mini" @click="updateBtn(row)">修改</el-button>
            <el-button v-if="row.IsValid === 0" size="mini" type="primary" @click="setValid(row)">启用</el-button>
            <el-button v-if="row.IsValid === 1" size="mini" type="warning" @click="setValid(row)">停用</el-button>
            <el-button size="mini" type="danger" @click="deleteBtn(row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination 
      :current-page= page
      :page-sizes="[10, 20, 30, 40]"
      :page-size= PageSize
      layout="total, prev, pager, next, sizes"
      :total= dataCount
      background
      @size-change="sizeChange"
      @current-change="currentChange">
    </el-pagination>

     <!-- 点击添加 显示添加表单 -->
    <el-dialog title="新增" width="760px" :visible.sync="dialogVisible1" :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="addForm" :model="addForm" class="addForm" label-width="100px" @submit.native.prevent :rules="rules">
        <el-form-item label="规则名称：" prop="name">
          <el-input class="formwid" v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="规则类型：" prop="ruleType">
          <el-select class="formwid" v-model="addForm.ruleType" :disabled="true">
             <el-option label="概率" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="概率一：" prop="prizeProbabilityList[0].probability">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[0].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额一(元)：" prop="prizeProbabilityList[0].amount">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[0].amount"></el-input>
        </el-form-item>
        <el-form-item label="概率二：" prop="prizeProbabilityList[1].probability">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[1].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额二(元)：" prop="prizeProbabilityList[1].amount">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[1].amount"></el-input>
        </el-form-item>
        <el-form-item label="概率三：" prop="prizeProbabilityList[2].probability">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[2].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额三(元)：" prop="prizeProbabilityList[2].amount">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[2].amount"></el-input>
        </el-form-item>
        <el-form-item label="概率四：" prop="prizeProbabilityList[3].probability">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[3].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额四(元)：" prop="prizeProbabilityList[3].amount">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[3].amount"></el-input>
        </el-form-item>
        <el-form-item label="概率五：" prop="prizeProbabilityList[4].probability">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[4].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额五(元)：" prop="prizeProbabilityList[4].amount">
          <el-input class="formwid" type="number" v-model="addForm.prizeProbabilityList[4].amount"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input class="formwid" v-model="addForm.remark"></el-input>
        </el-form-item>
        <el-form-item class="Form-btn">
          <el-button type="primary" @click="submitForm(addForm)">确 定</el-button>
          <el-button @click="resetForm('addForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
     <!-- 点击修改 显示修改表单 -->
    <el-dialog title="修改" width="760px" :visible.sync="dialogVisible2" :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="editForm" :model="editForm" class="editForm" label-width="100px" @submit.native.prevent>
        <el-form-item label="规则名称：" prop="name">
          <el-input class="formwid" v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="规则类型：" prop="ruleType">
          <el-select class="formwid" v-model="editForm.ruleType" :disabled="true">
             <el-option label="概率" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="概率一：" prop="prizeProbabilityList[0].probability">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[0].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额一(元)：" prop="prizeProbabilityList[0].amount">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[0].amount"></el-input>
        </el-form-item>
        <el-form-item label="概率二：" prop="prizeProbabilityList[1].probability">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[1].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额二(元)：" prop="prizeProbabilityList[1].amount">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[1].amount"></el-input>
        </el-form-item>
        <el-form-item label="概率三：" prop="prizeProbabilityList[2].probability">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[2].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额三(元)：" prop="prizeProbabilityList[2].amount">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[2].amount"></el-input>
        </el-form-item>
        <el-form-item label="概率四：" prop="prizeProbabilityList[3].probability">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[3].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额四(元)：" prop="prizeProbabilityList[3].amount">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[3].amount"></el-input>
        </el-form-item>
        <el-form-item label="概率五：" prop="prizeProbabilityList[4].probability">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[4].probability"></el-input>
        </el-form-item>
        <el-form-item label="金额五(元)：" prop="prizeProbabilityList[4].amount">
          <el-input class="formwid" type="number" v-model="editForm.prizeProbabilityList[4].amount"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input class="formwid" v-model="editForm.remark"></el-input>
        </el-form-item>
        <el-form-item class="Form-btn">
          <el-button type="primary" @click="updateForm(editForm)">确 定</el-button>
          <el-button @click="resetForm('editForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    
  </div>
</template>
<script>
import axios from 'axios';
import {PrizeRuleFindPageData,PrizeRuleAdd,PrizeRuleUpdate,PrizeRuleSetValid,PrizeRuleSetDelete,}from '@/api/managers'

export default {
  name: "createcode",
  data() {
    return {
      tableData: [],
      PageSize: 20,
      page: 1,
      dataCount: 0,
      dialogVisible1: false,
      dialogVisible2: false,
      addForm:{
        name:'',
        ruleType:'1',
        prizeProbabilityList:[{
          amount:'',
          probability:''
        },{
          amount:'',
          probability:''
        },{
          amount:'',
          probability:''
        },{
          amount:'',
          probability:''
        },{
          amount:'',
          probability:''
        }],
        remark:'',
        number:'',
      },
      editForm:{
        id:'',
        name:'',
        ruleType:'1',
        prizeProbabilityList:[{
          amount:'',
          probability:''
        },{
          amount:'',
          probability:''
        },{
          amount:'',
          probability:''
        },{
          amount:'',
          probability:''
        },{
          amount:'',
          probability:''
        }],
        remark:'',
        number:'',
      },
      rules:{
         name: [{required: true, message: '请输入规则名称',trigger: 'change'}],
      },
    }
  },
  // 导入组件
  components: {
    // 点聚合组件
  },
  // 创建完毕状态(里面是操作)
  created() { },
  // 挂载结束状态(里面是操作)
  mounted() {
    let data = {
      "keywords": this.keyword,
      "pageIndex": this.page,
      "pageSize": this.PageSize,
    }
    PrizeRuleFindPageData(data)
    .then(res=>{
      this.tableData = res.data;
      this.PageSize = res.PageSize;
      this.page = res.page;
      this.dataCount = res.dataCount;
      // console.log(this.tableData)
    })
    .catch(err=>{
      console.log(err)
    })
  },
  // 里面的函数只有调用才会执行
  methods: {
     //查询
    searchTable(){
      let data = {
        "keywords": this.keyword,
        "pageIndex": 1,
        "pageSize": this.PageSize,
      }
      // return
      PrizeRuleFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
        // console.log(this.tableData)
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 分页赋值
    // 点击改变页数
    sizeChange(val) {
      let data = {
        "pageIndex": 1,
        "pageSize": val,
        "keywords": this.keyword,
      }
      PrizeRuleFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 页面展示条数改变[10，20，30]
    currentChange(val){
      let data = {
        "pageIndex": val,
        "pageSize": this.PageSize,
        "keywords": this.keyword,
      }
      PrizeRuleFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = val;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err);
      })
    },

    // 新增发货单
    submitForm(addForm){
      const {name,prizeProbabilityList,ruleType,number,remark} = this.addForm;
      // const prizeProbabilityLists = [];
      prizeProbabilityList.forEach((item)=>{
        item.amount = item.amount + '';
        item.probability = item.probability + ''
      })
      let prizeProbabilityLists = prizeProbabilityList.filter((item)=> ![''].includes(item.amount,item.probability))
      let data={
        "name": name,
        "ruleType":ruleType,
        "remark":remark,
        "number":number,
        "prizeProbabilityList": prizeProbabilityLists
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          console.log(data)
          // return
          PrizeRuleAdd(data)
          .then(res=>{
            this.dialogVisible1 = false;
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            PrizeRuleFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName){
      this.$refs[formName].resetFields();
    },
    // 点击修改，获取数值
    updateBtn(row){
      // console.log(row)
      for(var i=0;i<row.PrizeProbabilityList.length;i++){
        this.editForm.prizeProbabilityList[i].amount = row.PrizeProbabilityList[i].Amount;
        this.editForm.prizeProbabilityList[i].probability = row.PrizeProbabilityList[i].Probability;
      }
      this.editForm.name = row.Name;
      this.editForm.number = row.Number;
      // this.editForm.prizeProbabilityList = row.PrizeProbabilityList;
      this.editForm.id = row.Id;
      this.editForm.remark = row.Remark;
      this.dialogVisible2 = true;
      console.log(this.editForm)
    },
    // 提交修改
    updateForm(editForm){
      const {name,prizeProbabilityList,ruleType,id,number,remark} = this.editForm;
      // const prizeProbabilityLists = [];
      prizeProbabilityList.forEach((item)=>{
        item.amount = item.amount + '';
        item.probability = item.probability + ''
      })
      let prizeProbabilityLists = prizeProbabilityList.filter((item)=> ![''].includes(item.amount,item.probability))
      let data={
        "id": id,
        "name": name,
        "ruleType":ruleType,
        "remark":remark,
        "number":number,
        "prizeProbabilityList": prizeProbabilityLists
      }
      // console.log(data)
      // return
      PrizeRuleUpdate(data)
      .then(res=>{
        console.log("修改成功")
        this.dialogVisible2 = false;
        let data = {
          "pageIndex": 1,
          "pageSize": this.PageSize,
          "keywords": this.keyword,
        }
        PrizeRuleFindPageData(data)
        .then(res=>{
          this.tableData = res.data;
          this.PageSize = res.PageSize;
          this.page = 1;
          this.dataCount = res.dataCount;
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log("修改失败")
      })
    },
    // 启用/停用
    setValid(row){
      let IsValid;
      if(row.IsValid === 1){
        this.$confirm('确定需要停用吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '确定停用!',
          });
          IsValid= 0;
          // console.log(IsValid)
          let params = {
            id: row.Id,
            valid: IsValid
          }
          PrizeRuleSetValid(params)
          .then(res=>{
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            PrizeRuleFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      }else if(row.IsValid === 0){
        this.$confirm('确定需要启用吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '确定启用!',
          });
          IsValid= 1;
          let params = {
            id: row.Id,
            valid: IsValid
          }
          PrizeRuleSetValid(params)
          .then(res=>{
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            PrizeRuleFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
        // IsValid = 0
      }
    },
    // 点击删除
    deleteBtn(row){
      this.$confirm('确定需要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
        let params={
          id: row.Id,
          isDeleted: 1,
        }
        // console.log(params)
        // return
        PrizeRuleSetDelete(params)
        .then(res=>{
          let data = {
            "pageIndex": 1,
            "pageSize": this.PageSize,
            "keywords": this.keyword,
          }
          PrizeRuleFindPageData(data)
          .then(res=>{
            this.tableData = res.data;
            this.PageSize = res.PageSize;
            this.page = 1;
            this.dataCount = res.dataCount;
          })
          .catch(err=>{
            console.log(err);
          })
        })
        .catch(err=>{
          console.log(err)
        })  
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消'
        });  
      })
    },
        
    handleClose(done) {
      done();
      if (this.$refs.addForm !== undefined)
      {this.$refs.addForm.resetFields()}
      if (this.$refs.editForm !== undefined)
      {this.$refs.editForm.resetFields()}
    },
  }
};
</script>
<style>


</style>