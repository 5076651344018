import axios from "axios";
import Cookies from 'js-cookie'
import router from "@/router";

const requset = axios.create({
  // baseURL: 'http://192.168.1.101:9002/api/',
  // baseURL: 'http://192.168.1.101:9002/api/',
  baseURL: 'https://www.xhgsun.cn:9002/api/',
  timeout: 900000,
})


// 请求拦截器
requset.interceptors.request.use(
  (config) =>{
    const token = Cookies.get('token')
    if(token){
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['Content-Type'] = 'application/json-patch+json'
    }
    return config;
  }
,
(error) => Promise.reject(error)
)

requset.interceptors.response.use(
  response =>{
    const res = response.data;

    if(response.status == 200){
      return res.response || res;
    }else{
      return Promise.reject()
    }
  
  },
  error =>{
    let response = error.response;
    const status = response.status;
    if (status ==401) {
      alert("您未登录，或者登录已经超时，请先登录！");
      router.replace({ path: "/login" });
    }
  }
)

export default requset;