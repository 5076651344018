import Vue from 'vue';
// 引入element ui
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue';
import './styles/index.css'
// 引入路由
import router from "./router";

// 引入vuex
import store  from './store/vuex';

// 引入icon
import './assets/icon/iconfont.css';
// 引入axios
import axios from 'axios';

import jsCookie from 'js-cookie'
import Cookies from 'js-cookie';
Vue.prototype.$cookie = jsCookie;
// 全局挂载axios
Vue.prototype.$axios = axios;

Vue.config.productionTip = false;

// 使用element ui
Vue.use(ElementUI);


router.beforeEach((to,from,next) => {
  // console.log(to);
  // console.log(from);
  // 验证token，只有存在token的时候，才能跳转到内容页
  const token = Cookies.get('token');
  if(to.name != 'Login' && !token){
    alert('您未登录，或者登录已经超时，请先登录！')
    next({name : 'Login'})
  }else{
    next()
  }
})


new Vue({
  render: h => h(App),
  axios,
  store,
  router,
  data:{
    Bus: new Vue()
  }
}).$mount('#app')