// 导入组件
import Vue from 'vue';
import Router from 'vue-router';
// 登录
import login from '@/views/login';
// 首页
import index from '@/views/index'
import home from '@/views/home/Home';

// 手动生码
import CreateCode from '@/views/createcode/CreateCode';
// 内外码关联
import RelationInOut from '@/views/relatecode/RelationInOut';
// 外码箱码关联
import RelationOutBox from '@/views/relatecode/RelationOutBox';
// 箱码跺码关联
import RelationPileBox from '@/views/relatecode/RelationPileBox';
// 中奖信息
import PrizeRule from '@/views/reward/PrizeRule';
// 充值
import PrizeActivity from '@/views/reward/PrizeActivity';
// 发货单
import invoice from '@/views/invoice/invoice';
// E码查询
import EcodeSearch from '@/views/ecodesearch/EcodeSearch';
// 产品组
import ProductGroup from '@/views/system/ProductGroup';
// 客户管理
import CustomerManager from '@/views/system/CustomerManager';
// 用户管理
import UserManager from '@/views/system/UserManager';
// 角色管理
import RoleManager from '@/views/system/RoleManager';
// 审计日志
import AuditLog from '@/views/system/AuditLog';
// 菜单管理
import Menu from '@/views/system/Menu';


// 启用路由
Vue.use(Router);

// 导出路由
// 配置路由和组件之间的应用关系
const routes= [
    {
      path: '/',
      redirect: '/index',
      hidden: true,
      meta: {
          requireAuth: true
      }
  },{
      path: '/login',
      component: login,
      hidden: true,
      meta: {
          requireAuth: false
      },
      name: 'Login'
  },{
      path: '/index',
      component: index,
      hidden: true,
      redirect: '/index/Home',
      meta:{
        requireAuth: false
      },
      children: [{
          path: '/index/CreateCode',
          component: CreateCode,
          name:'CreateCode'
      },{
          path: '/index/Home',
          component: home,
          name:'Home',
      },{
          path: '/index/RelationInOut',
          component: RelationInOut,
          name:'RelationInOut',
      },{
          path: '/index/RelationOutBox',
          component: RelationOutBox,
          name:'RelationOutBox',
      },{
          path: '/index/RelationPileBox',
          component: RelationPileBox,
          name:'RelationPileBox',
      },{
          path: '/index/PrizeRule',
          component: PrizeRule,
          name:'PrizeRule',
      },{
          path: '/index/PrizeActivity',
          component: PrizeActivity,
          name:'PrizeActivity',
      },{
          path: '/index/EcodeSearch',
          component: EcodeSearch,
          name:'EcodeSearch',
      },{
          path: '/index/ProductGroup',
          component: ProductGroup,
          name:'ProductGroup',
      },{
          path: '/index/CustomerManager',
          component: CustomerManager,
          name:'CustomerManager',
        },{
          path: '/index/UserManager',
          component: UserManager,
          name:'UserManager',
      },{
          path: '/index/RoleManager',
          component: RoleManager,
          name:'RoleManager',
      },{
          path: '/index/AuditLog',
          component: AuditLog,
          name:'AuditLog',
      },{
          path: '/index/Menu',
          component: Menu,
          name:'Menu',
      },{
        path: '/index/invoice',
        component: invoice,
        name:'invoice',
      }
    ]
  },{
    path: '**',
    redirect:'/index',
    hidden: true,
    meta: {
        requireAuth: true
    }
  }];

const router = new Router({
  routes,
  mode: 'history',
})

export default router;