/**
    产品组
 */


<template>
  <div class="stbox">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>产品组</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询名称或者编号 -->
    <el-form class="user-search" @submit.native.prevent>
      <el-form-item label="关键字：">
        <el-input type="text" v-model="keyword" size="small" placeholder="名称或者编号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="searchTable">查询</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-row class="plusdata">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible1 = true">新增</el-button>
    </el-row>
    <el-table :data="tableData" height="620" highlight-current-row  border  style="width: 100%;">
      <el-table-column align="center" type="index" width="60" label="#">
      </el-table-column>
      <el-table-column  prop="Name" label="产品名称" ></el-table-column>
      <el-table-column  prop="Code" label="产品编号" ></el-table-column>
      <el-table-column  prop="CreatorName" label="创建人" width="120"></el-table-column>
      <el-table-column prop="CreateTime" label="创建时间" width="180">
        <template slot-scope="{row}">
          <span>{{row.RevisionTime | resolvingDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Remark" label="备注"></el-table-column>
      <el-table-column align="center" label="操作" width="220">
        <template slot-scope="{row}">
          <div class="caozuo">
            <el-button size="mini" @click="updateBtn(row)">修改</el-button>
            <el-button v-if="row.IsValid === 0" size="mini" type="primary" @click="setValid(row)">启用</el-button>
            <el-button v-if="row.IsValid === 1" size="mini" type="warning" @click="setValid(row)">停用</el-button>
            <el-button size="mini" type="danger" @click="deleteBtn(row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination 
      :current-page= page
      :page-sizes="[10, 20, 30, 40]"
      :page-size= PageSize
      layout="total, prev, pager, next, sizes"
      :total= dataCount
      background
      @size-change="sizeChange"
      @current-change="currentChange">
    </el-pagination>
    <!-- 点击新增，弹出表单 --> 
    <el-dialog title="新增" destroy-on-close width="760px" :visible.sync="dialogVisible1" :before-close="handleClose" :close-on-click-modal="false">
      <el-form :rules="rules" ref="addForm" :model="addForm" class="addForm" label-width="120px" @submit.native.prevent>
        <el-form-item label="产品名称：" prop="productName">
          <el-input class="formwid" v-model="addForm.productName" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品编号：" prop="productCode">
          <el-input class="formwid" v-model="addForm.productCode" placeholder="请输入产品编号"></el-input>
        </el-form-item>
        <el-form-item label="产品类型：" prop="productType">
          <el-select class="formwid" v-model="addForm.productType" placeholder="请选择类型" clearable>
            <el-option label="内码" value="1"></el-option>
            <el-option label="外码" value="2"></el-option>
            <el-option label="内外码" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="每箱瓶数：" prop="bottleNumber">
          <el-input class="formwid" type="number" v-model="addForm.bottleNumber" placeholder="请输入每箱瓶数"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input class="formwid" v-model="addForm.remark" placeholder=""></el-input>
        </el-form-item>
        <el-form-item class="Form-btn">
          <el-button type="primary" @click="submitForm('addForm')">确 定</el-button>
          <el-button @click="resetForm('addForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 点击修改，弹出表单 -->
    <el-dialog title="修改" destroy-on-close width="760px" :visible.sync="dialogVisible2" :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="editForm" :model="editForm" class="editForm" label-width="120px" @submit.native.prevent>
        <el-form-item label="产品名称：" prop="productName">
          <el-input class="formwid" v-model="editForm.productName"></el-input>
        </el-form-item>
        <el-form-item label="产品编号：" prop="productCode">
          <el-input class="formwid" v-model="editForm.productCode"></el-input>
        </el-form-item>
        <el-form-item label="每箱瓶数：" prop="bottleNumber">
          <el-input class="formwid" type="number" v-model="editForm.bottleNumber" placeholder="请输入每箱瓶数"></el-input>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input class="formwid" v-model="editForm.remark"></el-input>
        </el-form-item>
        <el-form-item class="Form-btn">
          <el-button type="primary" @click="updateForm('editForm')">确 定</el-button>
          <el-button @click="resetForm('editForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {ProductFindPageData,ProductAdd,ProductSetValid,ProductUpdate,ProductSetDelete} from '@/api/managers'

export default {
  name: "productgroup",
   filters:{
    resolvingDate(date){
    //date是传入的时间
      let d = new Date(date);

      let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
      let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
      let hours = d.getHours()<10 ? '0'+d.getHours() : d.getHours();
      let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
      let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();

      let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;

      return times
    }
  },
  data() {
    return{
      PageSize: 20,
      page: 1,
      dataCount: 0,
      tableData: [],
      addForm: {
        productName: '',
        productCode:'',
        remark:'',
        productType: '',
        bottleNumber:'',
      },
      rules: {
        productName:[{required: true,message: '产品名称不能为空！！', trigger: 'blur'}],
        productCode:[{required: true,message: '产品编号不能为空！！',trigger: 'blur' }],
        productType:[{required: true,message: '产品类型不能为空！！',trigger: 'blur' }],
        bottleNumber:[{required: true,message: '每箱瓶数不能为空！！',trigger: 'blur' }],
      },
      editForm:{
        productName: '',
        productCode:'',
        remark:'',
        id:'',
        bottleNumber:''
      },
      dialogVisible1: false,
      dialogVisible2: false,
      keyword: ''
    }
  },
  mounted(){
    let data = {
      "keywords": this.keyword,
      "pageIndex": this.page,
      "pageSize": this.PageSize,
    }
    // return
    ProductFindPageData(data)
    .then(res=>{
      this.tableData = res.data;
      this.PageSize = res.PageSize;
      this.page = res.page;
      this.dataCount = res.dataCount;
      // console.log(this.tableData)
    })
    .catch(err=>{
      console.log(err)
    })
  },
  methods: {
    //查询
    searchTable(){
      let data = {
        "keywords": this.keyword,
        "pageIndex": this.page,
        "pageSize": this.PageSize,
      }
      // return
      ProductFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
        // console.log(this.tableData)
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 分页赋值
    // 点击改变页数
    sizeChange(val) {
      let data = {
        "pageIndex": 1,
        "pageSize": val,
        "keywords": this.keyword,
      }
      ProductFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 页面展示条数改变[10，20，30]
    currentChange(val){
      let data = {
        "pageIndex": val,
        "pageSize": this.PageSize,
        "keywords": this.keyword,
      }
      ProductFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = val;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err);
      })
    },
    
    handleClose(done) {
      done();
      if (this.$refs.addForm !== undefined)
      {this.$refs.addForm.resetFields()}
      if (this.$refs.editForm !== undefined)
      {this.$refs.editForm.resetFields()}
    },
    // 新增产品
    submitForm(addForm){
      const {productName,productCode,remark} = this.addForm;
      let data={
        "name": productName,
        "code": productCode,
        "remake": remark
      }
      this.$refs[addForm].validate((valid) => {
        if (valid) {
          ProductAdd(data)
          .then(res=>{
            this.dialogVisible1 = false;
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            ProductFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName){
      this.$refs[formName].resetFields();
      
    },
    // 点击修改，获取数值
    updateBtn(row){
      this.editForm.productName = row.Name;
      this.editForm.productCode = row.Code;
      this.editForm.remark = row.Remark;
      this.editForm.id = row.Id;
      this.dialogVisible2 = true;
    },
    // 提交修改
    updateForm(editForm){
      const {productName,productCode,remark,id} = this.editForm;
      // console.log(this.editForm)
      let data={
        "id": id,
        "name": productName,
        "code": productCode,
        "remake": remark,
      }
      // console.log(data)
      // return
      ProductUpdate(data)
      .then(res=>{
        // console.log("修改成功")
        this.dialogVisible2 = false;
        let data = {
          "pageIndex": 1,
          "pageSize": this.PageSize,
          "keywords": this.keyword,
        }
        ProductFindPageData(data)
        .then(res=>{
          this.tableData = res.data;
          this.PageSize = res.PageSize;
          this.page = 1;
          this.dataCount = res.dataCount;
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log("修改失败")
      })
    },
    
    // 启用/停用
    setValid(row){
      let IsValid;
      if(row.IsValid === 1){
        this.$confirm('确定需要停用吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '确定停用!',
          });
          IsValid= 0;
          // console.log(IsValid)
          let params = {
            id: row.Id,
            valid: IsValid
          }
          ProductSetValid(params)
          .then(res=>{
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            ProductFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      }else if(row.IsValid === 0){
        this.$confirm('确定需要启用吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '确定启用!',
          });
          IsValid= 1;
          let params = {
            id: row.Id,
            valid: IsValid
          }
          ProductSetValid(params)
          .then(res=>{
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            ProductFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
        // IsValid = 0
      }
    },
    // 点击删除
    deleteBtn(row){
      this.$confirm('确定需要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
        let params={
          id: row.Id,
          isDeleted: 1,
        }
        // console.log(params)
        // return
        ProductSetDelete(params)
        .then(res=>{
          let data = {
            "pageIndex": 1,
            "pageSize": this.PageSize,
            "keywords": this.keyword,
          }
          ProductFindPageData(data)
          .then(res=>{
            this.tableData = res.data;
            this.PageSize = res.PageSize;
            this.page = 1;
            this.dataCount = res.dataCount;
          })
          .catch(err=>{
            console.log(err);
          })
        })
        .catch(err=>{
          console.log(err)
        })  
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消'
        });  
      })
    },
  }
}
</script>