import Vue from 'vue';
import Vuex from 'vuex';
import Cookies from 'js-cookie'

Vue.use(Vuex);
// 登录验证
export default new Vuex.Store({
    state: {
        user: false
    },
    mutations: {
        // 登录
        login(state, user) {
            state.user = user;
            localStorage.setItem("userInfo", user);
            console.log(1)
        },
        // 退出
        logout(state, user) {
            state.user = "";
            console.log(state);
            Cookies.remove('token')
            // location.reload()
        }
    }
})