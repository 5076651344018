/** 内外码关联回传 */

<template>
  <!-- 组件主盒子 -->
  <div>
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>内外码关联回传</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询 -->
    <el-form
      class="user-search"
      @submit.native.prevent
      ref="FindFrom"
      :model="FindFrom"
    >

      <el-form-item
        label="时间范围"
        prop="value1"
      >
        <el-date-picker
          size="small"
          v-model="FindFrom.value1"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          format="yyyy-MM-dd HH:mm:ss"
          @change="CreatCodeTime"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button
          size="small"
          type="primary"
          icon="el-icon-search"
          @click="searchTable"
        >查询</el-button>
      </el-form-item>
    </el-form>
    <!--表格展示 + 文件回传和内外码模板 -->
    <el-row class="plusdata marbottom22">
      <el-button
        size="small"
        type="primary"
        icon="el-icon-refresh"
        @click="dialogVisible = true"
      >文件回传</el-button>
      <el-button
        size="small"
        type="primary"
        icon="el-icon-download"
        @click="Dowmload"
      >内外码关联模板</el-button>
    </el-row>
    <el-table
      class="marbottom22"
      :row-style="{height:'60px'}"
      :data="tableData"
      height="620"
      style="width: 100%; "
      :default-sort="{prop: 'date', order: 'descending'}"
      stripe
      border
    >
      <el-table-column
        align="center"
        type="index"
        width="60"
        label="#"
      ></el-table-column>
      <el-table-column
        prop="BatchNumber"
        label="批次号"
      ></el-table-column>
      <el-table-column
        prop="UploadFile"
        label="文件名称"
      ></el-table-column>
      <el-table-column
        prop="Number"
        label="生成数量"
        width="100"
      ></el-table-column>
      <el-table-column
        prop="CreatorName"
        label="创建人"
        width="120"
      ></el-table-column>
      <el-table-column
        prop="Remark"
        label="备注"
      ></el-table-column>
      <el-table-column
        prop="RevisionTime"
        label="创建时间"
        width="180"
      >
        <template slot-scope="{row}">
          <span>{{row.RevisionTime | resolvingDate}}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="Status"
        label="状态"
        width="120"
        align="center"
      >
        <template slot-scope="{row}">
          <el-button
            v-if="row.Status === 1"
            size="mini"
            :disabled="true"
            type="primary"
          >上传中</el-button>
          <el-button
            v-if="row.Status === 2"
            size="mini"
            :disabled="true"
            type="success"
          >上传成功</el-button>
          <el-button
            v-if="row.Status === -1"
            size="mini"
            :disabled="true"
            type="danger"
          >上传失败</el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="ExctionMsg"
        label="失败原因"
      ></el-table-column>

    </el-table>
    <!-- 分页器 -->
    <el-pagination
      :current-page=page
      :page-sizes="[10, 20, 30, 40]"
      :page-size=PageSize
      layout="total, prev, pager, next, sizes"
      :total=dataCount
      background
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>
    <!-- 点击文件回传，显示回传表单 -->
    <el-dialog
      title="文件回传"
      width="760px"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <el-form
        :rules="rules"
        ref="addForm"
        :model="addForm"
        class="addForm"
        label-width="120px"
        @submit.native.prevent
      >
        <el-form-item
          label="生成数量："
          prop="createNumber"
        >
          <el-input
            class="formwid"
            v-model="addForm.createNumber"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="备注："
          prop="remark"
        >
          <el-input
            class="formwid"
            v-model="addForm.remark"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="附件"
          prop="files"
        >
          <el-upload
            class="upload-demo"
            ref="upload"
            action=""
            :limit="1"
            :on-exceed="handleExceed"
            accept=".csv"
            :auto-upload="false"
            :before-upload="beforeUpload"
            :http-request="uploadFile"
          >
            <el-button
              class="el-icon-upload"
              size="small"
              type="primary"
            >点击上传</el-button>
            <div
              slot="tip"
              class="el-upload__tip"
            >只能上传csv文件，且不超过30M</div>
          </el-upload>
          <span></span>
        </el-form-item>
        <el-form-item class="Form-btn">
          <el-button
            type="primary"
            @click="submitForm"
          >确 定</el-button>
          <el-button @click="resetForm('addForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>


<script>
import axios from "axios";
import { OIFindPageModelData, OIDowmload } from '@/api/axios'
import Cookies from 'js-cookie'

export default {
  name: "relationinout",
  filters: {
    resolvingDate (date) {
      //date是传入的时间
      let d = new Date(date);

      let month = (d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1);
      let day = d.getDate() < 10 ? '0' + d.getDate() : d.getDate();
      let hours = d.getHours() < 10 ? '0' + d.getHours() : d.getHours();
      let min = d.getMinutes() < 10 ? '0' + d.getMinutes() : d.getMinutes();
      let sec = d.getSeconds() < 10 ? '0' + d.getSeconds() : d.getSeconds();

      let times = d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;

      return times
    }
  },
  data () {
    const validatorNum = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('数量不能为空！！'));
      } else {
        if (value <= 0) {
          callback(new Error('请输入正确的数量！！'));
        } else if (value > 300000) {
          callback(new Error('最大数量为300000！！'));
        } else if (isNaN(value)) {
          callback(new Error('请输入数字！！'));
        }
        callback();
      }
    };
    return {
      tableData: [],
      PageSize: 20,
      page: 1,
      dataCount: 0,
      NoError: '',
      dialogVisible: false,
      FindFrom: {
        batchNumber: '',
        customerId: '',
        value1: '',
        beginDate: '',
        endDate: '',
      },
      addForm: {
        createNumber: '',
        remark: '',
        files: null,
      },
      fileList: [],
      rules: {
        createNumber: [{ required: true, validator: validatorNum, trigger: 'change' }],
        files: [{ required: true, validator: this.validateFiles, }]
      }
    }
  },
  mounted () {
    let data = {
      "pageIndex": this.page,
      "pageSize": this.PageSize,
      "beginDate": this.beginDate,
      "endDate": this.endDate,
    }
    OIFindPageModelData(data)
      .then(res => {
        // console.log(res)
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
      })
      .catch(err => {
        console.log(err)
      })
  }
  ,
  methods: {
    validateFiles (rule, value, callback) {
      let files = this.$refs.upload.uploadFiles;
      if (!files.length) {
        callback(new Error('文件不能为空！！'));
      }
      callback();
    },
    // 获取查询时间范围
    CreatCodeTime (time) {
      if (time != null) {
        this.beginDate = time[0];
        this.endDate = time[1];
      } else {
        this.beginDate = '';
        this.endDate = '';
      }
    },
    CreatCodeCustomerId (val) {
      this.customerId = val;
    },
    CreatCodeBatchNumber (val) {
      this.batchNumber = val;
    },
    formatter (row, column) {
      return row.address;
    },

    searchTable () {
      let data = {
        "pageIndex": this.page,
        "pageSize": this.PageSize,
        "beginDate": this.beginDate,
        "endDate": this.endDate,
      }
      OIFindPageModelData(data)
        .then(res => {
          // console.log(res)
          this.tableData = res.data;
          this.PageSize = res.PageSize;
          this.page = res.page;
          this.dataCount = res.dataCount;
        })
        .catch(err => {
          console.log(err)
        })
    },

    // 分页赋值
    // 页面展示条数改变[10，20，30]
    currentChange (val) {
      // console.log(val,this.PageSize,this.page)
      let data = {
        "pageIndex": this.page,
        "pageSize": val,
      }
      OIFindPageModelData(data)
        .then(res => {
          // console.log(res,'---------1----------')
          this.CreateCodeData = res.data;
          this.PageSize = res.PageSize;
          this.page = res.page;
          // console.log(this.page)
        })
        .catch(err => {
          console.log(err)
        })
    },
    // 点击改变页数
    sizeChange (val) {
      // console.log(val);
      let data = {
        "pageIndex": val,
        "pageSize": this.PageSize,
      }
      // console.log(data);
      OIFindPageModelData(data)
        .then(res => {
          // console.log(res,'---------2----------',res.PageSize);
          this.CreateCodeData = res.data;
          this.PageSize = res.PageSize;
          this.page = val;
          // console.log(this.page)
        })
        .catch(err => {
          console.log(err);
        })
    },

    uploadFile (data) {
      const { createNumber, remark } = this.addForm;
      const token = Cookies.get('token')
      const formData = new FormData()
      formData.append("number", Number(createNumber))
      formData.append("remark", remark)
      formData.append("files", data.file)
      // console.log(formData.get("number"))
      // console.log(formData.get("remark"))
      // console.log(formData.get("files"))
      // return
      axios({
        // url:'http://192.168.1.101:9002/api/UploadOutIn/UploadFiles',
        url: 'https://www.xhgsun.cn:9002/api/UploadOutIn/UploadFiles',

        method: 'post',
        data: formData,
        headers: {
          Authorization: 'Bearer ' + token,
          'Content-Type': 'multipart/form-data'
        },
      }).then(res => {
        this.$message({
          message: '上传成功！！',
          type: 'success'
        });
        this.dialogVisible = false;
        OIFindPageModelData(data)
          .then(res => {
            // console.log(res)
            this.tableData = res.data
          })
          .catch(err => {
            console.log(err)
          })
      }).catch(err => {
        this.$message.error('上传失败');
      })
    },


    submitForm () {
      // console.log(this.$refs.addForm)
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$refs.upload.submit();
        }
      });
    },
    resetForm (formName) {
      // console.log(this.addForm,this.dialogVisible);
      this.$refs[formName].resetFields();
      this.$refs.upload.clearFiles();
    },
    handleClose (done) {
      done();
      if (this.$refs.addForm !== undefined) { this.$refs.addForm.resetFields() }
      if (this.$refs.upload !== undefined) { this.$refs.upload.clearFiles() }
    },
    handleRemove (file, fileList) {
      console.log(file, fileList);
    },
    handleExceed (files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件!`);
    },
    beforeUpload (file) {
      const isFile = file.size / 1024 / 1024 < 30
      if (!isFile) {
        this.$message.error('请上传30M以下的.csv文件')
        return false;
      }
    },
    beforeRemove (file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    clearFiles () {
      this.$refs.UploadFiles.clearFiles();
    },
    // 模板下载
    Dowmload () {
      OIDowmload()
        .then(res => {
          const element = document.createElement('a');
          element.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(res))
          element.setAttribute('download', '内外码上传模板.csv');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
        })
        .catch(err => {
          console.log(err);
        })
    }
  }
}
</script>

<style>
</style>