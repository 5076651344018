/**
    抽奖活动
 */


<template>
  <div class="stbox">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>抽奖活动</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询名称或者编号 -->
    <el-form class="user-search" @submit.native.prevent>
      <el-form-item label="关键字：">
        <el-input type="text" v-model="keyword" size="small" placeholder="名称或者编号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="searchTable">查询</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-row class="plusdata">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible1 = true">新增</el-button>
    </el-row>
    <el-table :data="tableData" height="620" highlight-current-row  border  style="width: 100%;">
      <el-table-column align="center" type="index" width="60" label="#">
      </el-table-column>
      <el-table-column  prop="Name" width="160" label="活动名称" ></el-table-column>
      <el-table-column  prop="Code" label="中奖规则" ></el-table-column>
      <el-table-column  prop="DeliveryBatch" label="单据编号" ></el-table-column>
      <el-table-column prop="CreateTime" label="开始时间" width="110">
        <template slot-scope="{row}">
          <span>{{row.BeginDate | resolvingDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="CreateTime" label="结束时间" width="110">
        <template slot-scope="{row}">
          <span>{{row.EndDate | resolvingDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="WhiteList" label="白名单"></el-table-column>
      <el-table-column prop="Remark" label="备注"></el-table-column>
      <el-table-column align="center" label="操作" width="220">
        <template slot-scope="{row}">
          <div class="caozuo">
            <el-button size="mini" @click="updateBtn(row)">修改</el-button>
            <el-button v-if="row.IsValid === 0" slot="reference" size="mini" type="primary" @click="setValid(row)">启用</el-button>
            <el-button v-if="row.IsValid === 1" slot="reference" size="mini" type="warning" @click="setValid(row)">停用</el-button>
            <el-button slot="reference" size="mini" type="danger" @click="deleteBtn(row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination 
      :current-page= page
      :page-sizes="[10, 20, 30, 40]"
      :page-size= PageSize
      layout="total, prev, pager, next, sizes"
      :total= dataCount
      background
      @size-change="sizeChange"
      @current-change="currentChange">
    </el-pagination>
    <!-- 点击新增，弹出表单 -->
    <el-dialog title="新增" destroy-on-close width="760px" :visible.sync="dialogVisible1" :before-close="handleClose" :close-on-click-modal="false">
      <el-form :rules="rules" ref="addForm" :model="addForm" class="addForm" label-width="120px" @submit.native.prevent>
        <el-form-item label="活动名称：" prop="prizeActivityName">
          <el-input class="formwid" v-model="addForm.prizeActivityName" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="中奖规则：" prop="prizeRuleList">
          <el-select class="formwid" v-model="addForm.prizeRuleList">
            <el-option
              v-for="item in prizeRuleName"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动时间：" prop="sendTime">
          <el-date-picker class="formwid "
            v-model="addForm.sendTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="CreatCodeTime">
          </el-date-picker>
        </el-form-item>
        
        <el-form-item label="单据编号：" prop="deliveryBatchList">
          <el-select class="formwid" multiple  v-model="addForm.deliveryBatchList">
            <el-option
              v-for="item in deliveryBatch"
              :key="item.value"
              :label="item.label"
              
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="白名单：" prop="whiteName">
          <el-select class="formwid" v-model="addForm.whiteName" multiple filterable allow-create default-first-option>
            <el-option item in whiteLists></el-option>
          </el-select>
          <el-input></el-input>
          <el-button @click="addDomain">新增白名单</el-button>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input class="formwid" v-model="addForm.remark" placeholder=""></el-input>
        </el-form-item>
        <el-form-item class="Form-btn">
          <el-button type="primary" @click="submitForm('addForm')">确 定</el-button>
          <el-button @click="resetForm('addForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 点击修改，弹出表单 -->
    <el-dialog title="修改" destroy-on-close width="760px" :visible.sync="dialogVisible2" :before-close="handleClose" :close-on-click-modal="false">
      <el-form :rules="rules" ref="editForm" :model="editForm" class="editForm" label-width="120px" @submit.native.prevent>
        <el-form-item label="活动名称：" prop="prizeActivityName">
          <el-input class="formwid" v-model="editForm.prizeActivityName" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="中奖规则：" prop="prizeRuleList">
          <el-select class="formwid" v-model="editForm.prizeRuleList">
            <el-option
              v-for="item in prizeRuleName"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="活动时间：" prop="sendTime">
          <el-date-picker class="formwid "
            v-model="editForm.sendTime"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            @change="CreatCodeTime">
          </el-date-picker>
        </el-form-item>
        
        <el-form-item label="单据编号：" prop="deliveryBatchList">
          <el-select class="formwid" multiple  v-model="editForm.deliveryBatchList">
            <el-option
              v-for="item in deliveryBatch"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="白名单：" prop="whiteName">
          <el-select class="formwid" v-model="editForm.whiteName" multiple filterable allow-create default-first-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="remark">
          <el-input class="formwid" v-model="editForm.remark" placeholder=""></el-input>
        </el-form-item>
        <el-form-item class="Form-btn">
          <el-button type="primary" @click="updateForm('editForm')">确 定</el-button>
          <el-button @click="resetForm('editForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {PrizeActivityFindPageData,PrizeActivityAdd,PrizeActivitySetValid,PrizeActivityUpdate,PrizeActivitySetDelete,GetPrizeRuleDrop,} from '@/api/managers'
import {DeliveryPlanFindPageData} from '@/api/axios'
import jsCookie from 'js-cookie';

export default {
  name: "productgroup",
   filters:{
    resolvingDate(date){
    //date是传入的时间
      let d = new Date(date);

      let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
      let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
      let hours = d.getHours()<10 ? '0'+d.getHours() : d.getHours();
      let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
      let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();

      let times=d.getFullYear() + '-' + month + '-' + day + ' ' ;
      return times
    }
  },
  data() {
    return{
      PageSize: 20,
      page: 1,
      dataCount: 0,
      tableData: [],
      prizeRuleName:[],      
      deliveryBatch:[],
      addForm: {
        prizeActivityName:'',
        prizeRuleList:'',
        deliveryBatchList:'',
        whiteName:'',
        remark:'',
        sendTime:[],
        beginDate:'',
        endDate:'',
      },
      rules: {
        PrizeActivityName:[{required: true,message: '产品名称不能为空！！', trigger: 'blur'}],
        productCode:[{required: true,message: '产品编号不能为空！！',trigger: 'blur' }],
      },
      editForm:{
        prizeActivityName:'',
        prizeRuleList:'',
        deliveryBatchList:'',
        whiteName:'',
        remark:'',
        sendTime:[],
        beginDate:'',
        endDate:'',
        id:''
      },
      dialogVisible1: false,
      dialogVisible2: false,
      keyword: ''
    }
  },
  mounted(){
    let data = {
      "keywords": this.keyword,
      "pageIndex": 1,
      "pageSize": this.PageSize,
    }
    // return
    PrizeActivityFindPageData(data)
    .then(res=>{
      this.tableData = res.data;
      this.PageSize = res.PageSize;
      this.page = res.page;
      this.dataCount = res.dataCount;
      console.log(this.tableData)
    })
    let data2={
      "keywords": this.keyword,
    }
    DeliveryPlanFindPageData(data2)
    .then(res=>{
      for(var i=0;i<res.data.length;i++){
        // console.log(res.data[i].BatchNumber)
        this.deliveryBatch.push({value:res.data[i].BatchNumber,label:res.data[i].BatchNumber})
      }
      // console.log(this.deliveryBatch)
    })
    .catch(err=>{
      console.log(err)
    })
    .catch(err=>{
      console.log(err)
    });
    GetPrizeRuleDrop()
    .then((res)=>{
      this.prizeRuleName = res;
      // console.log(this.prizeRuleName)
    })
    .catch((err)=>{
      console.log(err)
    })
  },
  methods: {
    //查询
    searchTable(){
      let data = {
        "keywords": this.keyword,
        "pageIndex": 1,
        "pageSize": this.PageSize,
      }
      // return
      PrizeActivityFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
        // console.log(this.tableData)
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 分页赋值
    // 点击改变页数
    sizeChange(val) {
      let data = {
        "pageIndex": 1,
        "pageSize": val,
        "keywords": this.keyword,
      }
      PrizeActivityFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 页面展示条数改变[10，20，30]
    currentChange(val){
      let data = {
        "pageIndex": val,
        "pageSize": this.PageSize,
        "keywords": this.keyword,
      }
      PrizeActivityFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = val;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err);
      })
    },
    
    handleClose(done) {
      done();
      if (this.$refs.addForm !== undefined)
      {this.$refs.addForm.resetFields()}
      if (this.$refs.editForm !== undefined)
      {this.$refs.editForm.resetFields()}
    },
    //获取时间
    CreatCodeTime(time){
      if(time !=null){
        this.addForm.beginDate = time[0];
        this.addForm.endDate = time[1];
      }else{
        this.addForm.beginDate = '';
        this.addForm.endDate = '';
      }
    },
    // 新增产品
    submitForm(addForm){
      const {prizeActivityName,prizeRuleList,beginDate,endDate,deliveryBatchList,remark,whiteName} = this.addForm;
      const deliveryBatch = deliveryBatchList.join(',');
      // for(var i=0;i++;i<whiteName.length){
      //   whiteName[i].
      // }
      const whiteList = whiteName.join(',');
      let data={
        "name": prizeActivityName,
        "remake": remark,
        "whiteList":whiteList,
        "deliveryBatch": deliveryBatch,
        // "productId": prizeRuleList,
        "beginDate":beginDate,
        "endDate":endDate,
      }
      this.$refs[addForm].validate((valid) => {
        if (valid) {
          console.log(data);
          return
          PrizeActivityAdd(data)
          .then(res=>{
            this.dialogVisible1 = false;
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            PrizeActivityFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName){
      this.$refs[formName].resetFields();
      
      
    },
    // 点击修改，获取数值
    updateBtn(row){
      this.editForm.prizeActivityName = row.Name;
      this.editForm.sendTime[0] = row.BeginDate;
      this.editForm.sendTime[1] = row.EndDate;
      this.editForm.beginDate = row.BeginDate;
      this.editForm.endDate = row.EndDate;
      this.editForm.whiteName = row.WhiteList.split(',');
      this.editForm.deliveryBatchList = row.DeliveryBatch.split(',');
      this.editForm.remark = row.Remark;
      this.editForm.id = row.Id;
      this.dialogVisible2 = true;
      console.log(this.editForm)
    },
    // 提交修改
    updateForm(editForm){
      const {prizeActivityName,beginDate,endDate,deliveryBatchList,remark,whiteName,id} = this.editForm;
      // console.log(this.editForm)
      const deliveryBatch = deliveryBatchList.join(',');
      const whiteList = whiteName.join(',')
      let data={
        "id": id,
        "name": prizeActivityName,
        "remake": remark,
        "whiteList":whiteList,
        "deliveryBatch": deliveryBatch,
        // "productId":'',
        "beginDate":beginDate,
        "endDate":endDate,
      }
      // console.log(data)
      // return
      PrizeActivityUpdate(data)
      .then(res=>{
        console.log("修改成功")
        this.dialogVisible2 = false;
        let data = {
          "pageIndex": 1,
          "pageSize": this.PageSize,
          "keywords": this.keyword,
        }
        PrizeActivityFindPageData(data)
        .then(res=>{
          this.tableData = res.data;
          this.PageSize = res.PageSize;
          this.page = 1;
          this.dataCount = res.dataCount;
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log("修改失败")
      })
    },
    // 启用/停用
    setValid(row){
      let IsValid;
      if(row.IsValid === 0){
        IsValid = 1
      }else if(row.IsValid === 1){
        IsValid = 0
      }
      let params = {
        id: row.Id,
        valid: IsValid
      }
      // console.log(row.IsValid,IsValid);
      // return
      PrizeActivitySetValid(params)
      .then(res=>{
        let data = {
          "pageIndex": 1,
          "pageSize": this.PageSize,
          "keywords": this.keyword,
        }
        PrizeActivityFindPageData(data)
        .then(res=>{
          this.tableData = res.data;
          this.PageSize = res.PageSize;
          this.page = 1;
          this.dataCount = res.dataCount;
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 点击删除
    deleteBtn(row){
      let params={
        id: row.Id,
        isDeleted: 1,
      }
      // console.log(data)
      PrizeActivitySetDelete(params)
      .then(res=>{
        let data = {
          "pageIndex": 1,
          "pageSize": this.PageSize,
          "keywords": this.keyword,
        }
        PrizeActivityFindPageData(data)
        .then(res=>{
          this.tableData = res.data;
          this.PageSize = res.PageSize;
          this.page = 1;
          this.dataCount = res.dataCount;
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log(err)
      })
    },
  }
}
</script>