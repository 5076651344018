/**头部菜单 */

<template>
  <div>
    <div class="headerbg">
      <el-dropdown class="userzt">
        <span class="el-dropdown-link">企业名称：
          账套001<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>账套001</el-dropdown-item>
          <el-dropdown-item>账套002</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span class="Timeinfo">{{todayTime | resolvingDate}}</span>
      <el-dropdown @command="handleCommand" class="uesrinfo">
        <span class="el-dropdown-link">
          {{CustomerName}}-{{DspName}}<i class="el-icon-arrow-down el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="xgmm">修改密码</el-dropdown-item>
          <el-dropdown-item command="exit">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-dialog title="修改密码" width="460px" :visible.sync="dialogVisible" :before-close="handleClose">
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm">
        <el-form-item label="新密码" prop="pass">
          <el-input type="password" v-model="ruleForm.pass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="ruleForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <div class="Form-btn">
          <el-button type="primary" @click="submitForm('addForm')">确 定</el-button>
          <el-button @click="resetForm('addForm')">重 置</el-button>
        </div>
      </el-form>
      <!-- <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div> -->
    </el-dialog>
  </div>
</template>
<script>
import store from "@/store/vuex"
import Cookies from 'js-cookie'

export default {
  name: 'headnav',
  filters:{
    resolvingDate(date){
    //date是传入的时间
      let d = new Date(date);

      let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
      let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();

      let times=d.getFullYear() + '-' + month + '-' + day + ' ';
      return times
    }
  },
  data() {
    var validatePass = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.ruleForm.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rules, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };
    return {
      collapsed: true,
      user: {},
      dialogVisible: false,
      isCollapse: true,
      ruleForm: {
        pass: '',
        checkPass: '',
      },
      rules: {
        pass: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      CustomerName:'',
      DspName:'',
      money:'',
      expiry_date:'',
      todayTime: '',
    };
  },
  // 创建完毕状态(里面是操作)
  mounted() {
    this.CustomerName= Cookies.get('CustomerName') || '管理员';
    this.DspName = Cookies.get('DspName') || '';
    this.money = Cookies.get('money') || '';
    this.expiry_date = Cookies.get('expiry_date') || '';
    // console.log('----CustomerName-----',this.CustomerName)
    // console.log('----DspName-----',this.DspName)
    this.todayTime = Date.now()
  },
  methods: {
    // 退出登录
    exit() {
      this.$confirm('退出登录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
      .then(() => {
        setTimeout(() => {
          store.commit('logout', 'false')
          this.$router.push({ path: '/login' })
          this.$message({
            type: 'success',
            message: '已退出登录!'
          })
        }, 1000)
      })
      .catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    xgmm(){
      this.dialogVisible =true;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleCommand(command) {
      switch (command){
        case "xgmm":
          this.xgmm();
          break;
        case "exit":
          this.exit();
          break;
      }
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          alert('submit!');
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(ruleForm) {
      this.$refs[ruleForm].resetFields();
    },
  }
}
</script>
<style scoped>
.el-dropdown{
  margin-right: 20px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #ffffff;
}
.headerbg{
  width: 100%;
  height: 60px;
  background-color: #fff ;
  line-height: 60px;
  color: #000;
  /* text-align: right; */
  border-bottom: solid 1px #f2f2f2;
}
.uselogin{
  font-size: 14px;
  padding: 0 20px;
}
.uesrinfo{
  float: right;
}
.userzt{
  margin-left: 30px;
}
.Timeinfo{
  font-size: 14px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #000;
}
.el-icon-arrow-down {
  font-size: 12px;
}
</style>