/**
* 二维码生成
*/ 

<template>
  <!-- 组件主盒子 -->
  <div class="stbox">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>二维码生成</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询 -->
    <el-form class="user-search" @submit.native.prevent ref="FindCreateFrom" :model="FindCreateFrom">
      <!-- <el-form-item label="企业名称" prop="customerId">
        <el-select v-model="FindCreateFrom.customerId" size="small" placeholder="请选择" clearable @change="CreatCodeCustomerId">
          <el-option value="43F446F43E48402086783D8D3802BD88" label="海普制盖"></el-option>
          <el-option value="DA8DA75085AB4D6DA152775F85786FA6" label="绍兴新华制盖"></el-option>
        </el-select>
      </el-form-item> -->
      <!-- <el-form-item label="产品名称" prop="customerId">
        <el-select v-model="FindCreateFrom.customerId" size="small" placeholder="请选择" clearable @change="CreatCodeCustomerId">
        </el-select>
      </el-form-item> -->
      <el-form-item label="时间范围" prop="value1">
          <el-date-picker size="small" 
            v-model="FindCreateFrom.value1" 
            type="datetimerange" 
            start-placeholder="开始日期" 
            end-placeholder="结束日期" 
            :default-time="['00:00:00', '23:59:59']"
            format="yyyy-MM-dd HH:mm:ss"
            @change="CreatCodeTime">
            </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="findCodeData">查询</el-button>
      </el-form-item>
    </el-form>
    <!--表格展示 + 点击新增数据 -->
    <el-row class="plusdata marbottom22">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible = true">新增</el-button>
    </el-row>
    <el-table class="marbottom22" :data="tableData" height="620" style="width: 100%; " :default-sort = "{prop: 'date', order: 'descending'}" stripe border
    >
      <el-table-column align="center" type="index" width="60" label=""></el-table-column>
      <el-table-column prop="Id" label="批次号"></el-table-column>
      <el-table-column prop="CreateNumber" label="生成数量" ></el-table-column>
      <el-table-column prop="CodeType" label="生成规则" >
        <template slot-scope="{row}">
          <span v-if="row.CodeType ===1">内码</span>
          <span v-if="row.CodeType ===2">外码</span>
          <span v-if="row.CodeType ===3">箱码</span>
        </template>
      </el-table-column>
      <el-table-column prop="CreatorName" label="创建人"></el-table-column>
      <el-table-column prop="RevisionTime" label="创建时间">
        <template slot-scope="{row}">
          <span>{{row.RevisionTime | resolvingDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Status" label="状态" width="120" align="center">
        <template slot-scope="{row}">
          <el-button v-if="row.Status === 1" size="mini" :disabled="true" type="primary">生成中</el-button>
          <el-button v-if="row.Status === 2" size="mini" :disabled="false" type="success" @click="downCreatCode(row.Id)">下载</el-button>
          <el-button v-if="row.Status === 3" size="mini" :disabled="true" type="info" >已下载</el-button>
          <el-button v-if="row.Status === -1" size="mini" :disabled="true" type="danger">生成失败</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="激活" width="100" align="center"></el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination 
      :current-page= page
      :page-sizes="[10, 20, 30, 40]"
      :page-size= PageSize
      layout="total, prev, pager, next, sizes"
      :total= dataCount
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange">
    </el-pagination>

    <!-- 点击添加 显示添加表单 -->
    <el-dialog title="新增" width="760px" :visible.sync="dialogVisible" :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="addForm" :model="addForm" class="addForm" label-width="120px" @submit.native.prevent :rules="rules">
       <el-form-item label="二维码种类：" prop="codeType">
        <el-select class="formwid" v-model="addForm.codeType" placeholder="请选择类型" clearable>
          <el-option label="内码" value="1"></el-option>
          <el-option label="外码" value="2"></el-option>
          <el-option label="箱码" value="3"></el-option>
        </el-select>
       </el-form-item>
       <el-form-item label="生成数量：" prop="createNumber">
         <el-input class="formwid" v-model="addForm.createNumber"></el-input>
       </el-form-item>
       <el-form-item label="备注：" prop="remark">
         <el-input class="formwid" v-model="addForm.remark"></el-input>
       </el-form-item>
       <el-form-item class="Form-btn">
        <el-button type="primary" @click="submitForm('addForm')">确 定</el-button>
        <el-button @click="resetForm('addForm')">重 置</el-button>
       </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { FindPageCreateCodeData,Download,CreateCodeAdd } from '@/api/axios';


export default {
  name: "createcode",
  filters:{
    resolvingDate(date){
    //date是传入的时间
      let d = new Date(date);

      let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
      let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
      let hours = d.getHours()<10 ? '0'+d.getHours() : d.getHours();
      let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
      let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();

      let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;

      return times
    }
  },
  data() {
    const validatorNum = (rules,value,callback) =>{
      if (value === ''){
        callback(new Error('数量不能为空！！'));
      }else{
        if(value <= 0){
          callback(new Error('请输入正确的数量！！'));
        }else if(isNaN(value)){
          callback(new Error('请输入数字！！'));
        }
        callback();
      }
    };
    return {
      tableData: [],
      PageSize: 20,
      page: 1,
      dataCount: 0,
      NoError:'',
      dialogVisible: false,
      FindCreateFrom:{
        batchNumber: '',
        customerId: '',
        value1: '',
        beginDate:'',
        endDate:'',
      },
      addForm: {
        createNumber: '',
        remark: '',
        codeType: '',
        downUserId: '',
        downUserName: '',
      },
      rules: {
        createNumber:[
          {required: true,validator: validatorNum, trigger: 'change'}
        ],
        codeType: [{required: true, message: '请选择类型',trigger: 'change'}],
      }
    }
  },
  mounted(){
    this.downUserId = Cookies.get('CustomerName') || '';
    this.downUserName = Cookies.get('DspName') || '';
    let data = {
      "pageIndex": 1,
      "pageSize": this.pageSize,
      "batchNumber": this.batchNumber,
      "beginDate": this.beginDate,
      "endDate": this.endDate,
    }
    // console.log(data)
    FindPageCreateCodeData(data)
    .then(res=>{
      this.tableData = res.data;
      this.PageSize = res.PageSize;
      this.page = res.page;
      this.dataCount = res.dataCount;
    })
    .catch(err=>{
      console.log(err);
    })
  },
  // 里面的函数只有调用才会执行
  methods: {

    // 分页赋值
     // 点击改变页数
    handleCurrentChange(val){
      // console.log(val);
      let data = {
        "pageIndex": val,
        "pageSize": this.PageSize,
        "batchNumber": this.batchNumber,
        "customerId": this.customerId,
        "beginDate": this.beginDate,
        "endDate": this.endDate,
      }
      // console.log(data);
      FindPageCreateCodeData(data)
      .then(res=>{
        // console.log(res,'---------2----------',res.PageSize);
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = val;
        // console.log(this.page)
      })
      .catch(err=>{
        console.log(err);
      })
    },
    // 页面展示条数改变[10，20，30]
    handleSizeChange(val) {
      // console.log(val);

      // console.log(val,this.PageSize,this.page)
      let data = {
        "pageIndex": 1,
        "pageSize": val,
        "batchNumber": this.batchNumber,
        "customerId": this.customerId,
        "beginDate": this.beginDate,
        "endDate": this.endDate,
      }
      FindPageCreateCodeData(data)
      .then(res=>{
        // console.log(res,'---------1----------')
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        console.log(this.page)
      })
      .catch(err=>{
        console.log(err)
      })
    },
   
    // 获取查询时间范围
    CreatCodeTime(time){
      if(time !=null){
        this.beginDate = time[0];
        this.endDate = time[1];
      }else{
        this.beginDate = '';
        this.endDate = '';
      }
    },
    CreatCodeCustomerId(val){
      this.customerId = val;
    },
    CreatCodeBatchNumber(val){
      this.batchNumber = val;
    },
    // 查询生码
    findCodeData(){
      let data = {
        "pageIndex": 1,
        "pageSize": this.pageSize,
        "batchNumber": this.batchNumber,
        "beginDate": this.beginDate,
        "endDate": this.endDate,
      }
      // console.log(data)
      FindPageCreateCodeData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err);
      })
    },
    // 新增生码
    submitForm(addForm){
      // console.log(this.addForm,this.dialogVisible);
      const {createNumber,remark,codeType} = this.addForm;
      let data = {
        "codeType": Number(codeType),
        "createNumber": Number(createNumber),
        "remark": remark,
        "downUserId": this.downUserId,
        "downUserName": this.downUserName,
      }
      this.$refs[addForm].validate((valid) => {
        if (valid) {
          CreateCodeAdd(data)
          .then(res=>{
            console.log(res);
            this.dialogVisible = false;
            FindPageCreateCodeData(data)
            .then(res=>{
              console.log(res,'---------2----------',res.PageSize);
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = val;
              console.log(this.page)
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
      // return;
      
    },
    // 下载文件
    downCreatCode(data){
      let params={
        fileName: data
      }
      Download(params)
      .then(res=>{
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res))
        element.setAttribute('download', data);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .catch(err=>{
        console.log(err)
      })
    },
    resetForm(addForm){
      console.log(this.addForm,this.dialogVisible);
      this.$refs[addForm].resetFields();
    },
    handleClose(done) {
      done();
      if (this.$refs.addForm !== undefined)
      {this.$refs.addForm.resetFields()}
      if (this.$refs.editForm !== undefined)
      {this.$refs.editForm.resetFields()}
    },
  },

};
</script>
<style>


</style>