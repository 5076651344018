/**
    E码查询
 */


<template>
  <div class="stbox">
  <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>E码查询</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form class="user-search" @submit.native.prevent>
      <el-form-item label="类型：">
        <el-select v-model="op" size="small" placeholder="请选择">
          <el-option value="内码"></el-option>
          <el-option value="外码"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="码：">
        <el-input type="text" v-model="text" style="width:320px;" placeholder="例如：5168432168132168132168"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search">查询</el-button>
      </el-form-item>
    </el-form>
    <el-table class="marbottom22" :data="tableData" height="620" style="width: 100%; " :default-sort = "{prop: 'date', order: 'descending'}" stripe border
    >
      <el-table-column align="center" type="index" width="60" label=""></el-table-column>
      <el-table-column prop="Id" label="批次号"></el-table-column>
      <el-table-column prop="CreateNumber" label="生成数量" ></el-table-column>
      <el-table-column prop="CodeType" label="生成规则" >
        <template slot-scope="{row}">
          <span v-if="row.CodeType ===1">内码</span>
          <span v-if="row.CodeType ===2">外码</span>
          <span v-if="row.CodeType ===3">箱码</span>
          <span v-if="row.CodeType ===4">跺码</span>
        </template>
      </el-table-column>
      <el-table-column prop="CreatorName" label="创建人"></el-table-column>
      <el-table-column prop="RevisionTime" label="创建时间">
        <template slot-scope="{row}">
          <span>{{row.RevisionTime | resolvingDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="Status" label="状态" width="120" align="center">
        <template slot-scope="{row}">
          <el-button v-if="row.Status === 1" size="mini" :disabled="true" type="primary">生成中</el-button>
          <el-button v-if="row.Status === 2" size="mini" :disabled="false" type="success" @click="downCreatCode(row.Id)">下载</el-button>
          <el-button v-if="row.Status === 3" size="mini" :disabled="true" type="info" >已下载</el-button>
          <el-button v-if="row.Status === -1" size="mini" :disabled="true" type="danger">生成失败</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="state" label="激活" width="100" align="center"></el-table-column>
    </el-table>
  </div>
  
</template>

<script>

export default {
  name: 'ecodesearch',
  data(){
    return{
      op: '',
      text:'',
      tableData:[]
    }
  }
}
</script>
