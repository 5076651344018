import requset from '@/utils/requset';

// 获取token
export function GetApiToken(params){
  return requset({
    url:'Login/GetApiToken',
    method:'get',
    params
  })
}

// 获取用户信息
export function FindByAccount(params){
  return requset({
    url:'CommonUser/FindByAccount',
    method:'get',
    params
  })
}

// 获取角色信息
export function GetCommonRoleByUserId(params){
  return requset({
    url:'CommonRole/GetCommonRoleByUserId',
    method:'get',
    params
  })
}


export function GetMenuByRoleId(params){
  return requset({
    url:'CommonMenu/GetMenuByRoleId',
    method:'get',
    params
  })
}
// 获取菜单树
export function GetMenuTree(params){
  return requset({
    url:'CommonMenu/GetMenuTree',
    method:'get',
    params
  })
}