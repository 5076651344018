/**
* 发货单
*/ 

<template>
  <!-- 组件主盒子 -->
  <div class="stbox">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>发货单</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form class="user-search" @submit.native.prevent>
      <el-form-item label="关键字：">
        <el-input type="text" v-model="keyword" size="small"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="searchTable">查询</el-button>
      </el-form-item>
    </el-form>
    <el-row class="plusdata marbottom22">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible1 = true">新增</el-button>
    </el-row>
    <el-table :data="tableData" height="620" highlight-current-row  border  style="width: 100%;">
      <el-table-column align="" type="index" width="60" label="#"></el-table-column>
      <el-table-column prop="Send_Time" label="发货日期" width="170">
         <template slot-scope="{row}">
          <span>{{row.Send_Time | resolvingDate}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="BatchNumber" label="单据编号" width="160"></el-table-column>
      <el-table-column prop="ProductName" label="产品" width="240"></el-table-column>
      <el-table-column prop="PreSetNumber" label="数量" width="120"></el-table-column>      
      <el-table-column prop="Dealer_Name" label="经销商" width="140"></el-table-column>
      <el-table-column prop="Area_Name" label="销售区域" width="140"></el-table-column>
      <el-table-column prop="Remark" label="备注" width="240"></el-table-column>
      <el-table-column prop="CreatorName" label="制单人" width="120"></el-table-column>
      <!-- <el-table-column prop="" label="审核人" width="100"></el-table-column> -->
      <el-table-column prop="Send_Name" label="发货人" width="120"></el-table-column>
      <el-table-column prop="DeliveryStatus" label="发货状态" width="120">
        <template slot-scope="{row}">
          <el-button v-if="row.DeliveryStatus === 1" size="mini" :disabled="true" type="warning">预发货</el-button>
          <el-button v-if="row.DeliveryStatus === 2" size="mini" :disabled="true" type="primary" >正在发货</el-button>
          <el-button v-if="row.DeliveryStatus === 3" size="mini" :disabled="true" type="success">发货完成</el-button>
        </template>
      </el-table-column>
      <el-table-column prop="PrizeActive_Name" label="活动规则" width="140"></el-table-column>
      <el-table-column align="center" label="操作" width="280" fixed="right">
        <template slot-scope="{row}">
          <div class="caozuo">
            <el-button size="mini">审核</el-button>
            <el-button size="mini" type="success" @click="updateBtn(row)">修改</el-button>
            <el-button v-if="row.IsValid === 0" slot="reference" size="mini" type="primary" @click="setValid(row)">启用</el-button>
            <el-button v-if="row.IsValid === 1" slot="reference" size="mini" type="warning" @click="setValid(row)">停用</el-button>
            <el-button slot="reference" size="mini" type="danger" @click="deleteBtn(row)">删除</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination 
      :current-page= page
      :page-sizes="[10, 20, 30, 40]"
      :page-size= PageSize
      layout="total, prev, pager, next, sizes"
      :total= dataCount
      background
      @size-change="sizeChange"
      @current-change="currentChange">
    </el-pagination>

    <!-- 点击添加 显示添加表单 -->
    <el-dialog title="新增" width="760px" :visible.sync="dialogVisible1" :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="addForm" :model="addForm" class="addForm" label-width="100px" @submit.native.prevent :rules="rules">
        <el-form-item label="发货日期：" prop="sendTime">
          <el-date-picker class="formwid" v-model="addForm.sendTime" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="经销商：" prop="dealerList">
          <el-select class="formwid" v-model="addForm.dealerList">
            <el-option
              v-for="item in dealerName"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售区域：" prop="areaList">
          <el-select class="formwid" v-model="addForm.areaList">
            <el-option
              v-for="item in areaName"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品：" prop="productList">
          <el-select class="formwid" v-model="addForm.productList">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量：" prop="preSetNumber">
          <el-input class="formwid" v-model="addForm.preSetNumber"></el-input>
        </el-form-item>
        <!-- <el-form-item label="发货人：" prop="sendName">
          <el-input class="formwid" v-model="addForm.sendName"></el-input>
        </el-form-item> -->
        <el-form-item label="制单人：" prop="creatorName">
          <el-input class="formwid" :disabled="true" v-model="addForm.creatorName"></el-input>
        </el-form-item>
        <!-- <el-form-item label="备注：" prop="remark">
          <el-input class="formwid" v-model="addForm.remark"></el-input>
        </el-form-item> -->
        <!-- <el-form-item label="活动规则：" prop="prizeActiveName">
          <el-input class="formwid" v-model="addForm.prizeActiveName"></el-input>
        </el-form-item> -->
        <el-form-item class="Form-btn">
          <el-button type="primary" @click="submitForm(addForm)">确 定</el-button>
          <el-button @click="resetForm('addForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 点击修改 显示修改表单 -->
    <el-dialog title="修改" width="760px" :visible.sync="dialogVisible2" :before-close="handleClose" :close-on-click-modal="false">
      <el-form ref="editForm" :model="editForm" class="editForm" label-width="120px" @submit.native.prevent>
        <el-form-item label="发货日期：" prop="sendTime">
          <el-date-picker class="formwid" v-model="editForm.sendTime" type="date" placeholder="选择日期"></el-date-picker>
        </el-form-item>
        <el-form-item label="经销商：" prop="dealerList">
          <el-select class="formwid" v-model="editForm.dealerList">
            <el-option
              v-for="item in dealerName"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="销售区域：" prop="areaList">
          <el-select class="formwid" v-model="editForm.areaList">
            <el-option
              v-for="item in areaName"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="产品：" prop="productList">
          <el-select class="formwid" v-model="editForm.productList">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="数量：" prop="preSetNumber">
          <el-input class="formwid" v-model="editForm.preSetNumber"></el-input>
        </el-form-item>
        <el-form-item label="制单人：" prop="creatorName">
          <el-input class="formwid" :disabled="true" v-model="editForm.creatorName"></el-input>
        </el-form-item>
        <el-form-item label="单据编号：" prop="batchNumber">
          <el-input class="formwid" :disabled="true" v-model="editForm.batchNumber"></el-input>
        </el-form-item>
        <el-form-item class="Form-btn">
          <el-button type="primary" @click="updateForm('editForm')">确 定</el-button>
          <el-button @click="resetForm('editForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>

import Cookies from 'js-cookie';
import { DeliveryPlanFindPageData,DeliveryPlanAdd,DeliveryPlanUpdate,DeliveryPlanSetValid,DeliveryPlanSetDelete } from '@/api/axios';
import {GetProductDrop,GetDealerDrop,}from '@/api/managers'


export default {
  name: "invoice",
  filters:{
    resolvingDate(date){
    //date是传入的时间
      let d = new Date(date);

      let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
      let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
      let hours = d.getHours()<10 ? '0'+d.getHours() : d.getHours();
      let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
      let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();

      let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;

      return times
    }
  },
  data() {
    const validatorNum = (rules,value,callback) =>{
      if (value === ''){
        callback(new Error('数量不能为空'));
      }else{
        if(value <= 0){
          callback(new Error('请输入正确的数量！！'));
        }else if(isNaN(value)){
          callback(new Error('请输入数字！！'));
        }
        callback();
      }
    };
    return {
      tableData: [],
      PageSize: 20,
      page: 1,
      dataCount: 0,
      options:[],
      dealerName:[],
      areaName:[],
      dialogVisible1: false,
      dialogVisible2: false,
      addForm: {
        dealerList:'',
        areaList:'',
        sendTime:'',
        batchNumber:'',
        creatorName:'',
        remark:'',
        sendName:'',
        productName:'',
        preSetNumber:'',
        prizeActiveName:'',
        productList:'',
      },
      editForm: {
        id:'',
        sendTime:'',
        dealerList:'',
        areaList:'',
        batchNumber:'',
        productName:'',
        preSetNumber:'',
        productList:'',
      },
      rules: {
        preSetNumber:[{required: true,validator: validatorNum, trigger: 'change'}],
        sendTime: [{required: true, message: '请选择发货日期',trigger: 'change'}],
        dealerName: [{required: true, message: '请选择经销商',trigger: 'change'}],
        areaName: [{required: true, message: '请选择销售区域',trigger: 'change'}],
        productList: [{required: true, message: '请选择经产品',trigger: 'change'}],
      },
      keyword:'',
    }
  },
  mounted(){
    this.addForm.creatorName = Cookies.get('DspName') || '';
    this.editForm.creatorName = Cookies.get('DspName') || '';

    GetProductDrop()
    .then(res=>{
      // console.log(res)
      this.options = res;
      console.log(this.options)
    })
    .catch(err=>{
      console.log(err)
    });

    // 获取区域列表
    let params1={
      type: 1
    }
    GetDealerDrop(params1)
    .then(res=>{
      this.areaName = res;
      // console.log(this.areaName)
    })
    // 获取经销商列表
    let params2={
      type: 2
    }
    GetDealerDrop(params2)
    .then(res=>{
      this.dealerName = res;
      // console.log(this.dealerName)

    })
    .catch(err=>{
      console.log(err)
    })
    let data = {
      "keywords": this.keyword,
      "pageIndex": 1,
      "pageSize": this.PageSize,
    }
    // return
    DeliveryPlanFindPageData(data)
    .then(res=>{
      this.tableData = res.data;
      this.PageSize = res.PageSize;
      this.page = res.page;
      this.dataCount = res.dataCount;
      // console.log(this.tableData)
    })
    .catch(err=>{
      console.log(err)
    })
  },
  // 里面的函数只有调用才会执行
  methods: {
    //查询
    searchTable(){
      let data = {
        "keywords": this.keyword,
        "pageIndex": 1,
        "pageSize": this.PageSize,
      }
      // return
      DeliveryPlanFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
        console.log(this.tableData)
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 分页赋值
    // 点击改变页数
    sizeChange(val) {
      let data = {
        "pageIndex": 1,
        "pageSize": val,
        "keywords": this.keyword,
      }
      DeliveryPlanFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 页面展示条数改变[10，20，30]
    currentChange(val){
      let data = {
        "pageIndex": val,
        "pageSize": this.PageSize,
        "keywords": this.keyword,
      }
      DeliveryPlanFindPageData(data)
      .then(res=>{
        this.tableData = res.data;
        this.PageSize = res.PageSize;
        this.page = val;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err);
      })
    },

    // 新增发货单
    submitForm(addForm){
      const {preSetNumber,sendTime,areaList,dealerList,productList,remark} = this.addForm;
      let data={
        // "batchNumber": sendTime,
        "preSetNumber": preSetNumber,
        "area_Id":areaList,
        "productId":productList,
        "dealer_Id":dealerList,
        "send_Time": sendTime
      }
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          console.log(data)
          // return
          DeliveryPlanAdd(data)
          .then(res=>{
            this.dialogVisible1 = false;
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            DeliveryPlanFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 重置表单
    resetForm(formName){
      this.$refs[formName].resetFields();
    },
    // 点击修改，获取数值
    updateBtn(row){
      console.log(row)
      this.editForm.productList = row.ProductId;
      this.editForm.sendTime = row.Send_Time;
      this.editForm.batchNumber = row.BatchNumber;
      this.editForm.id = row.Id;
      this.editForm.dealerList = row.Dealer_Id;
      this.editForm.areaList = row.Area_Id;
      this.editForm.preSetNumber = row.PreSetNumber;
      this.dialogVisible2 = true;
    },
    // 提交修改
    updateForm(editForm){
      const {productList,sendTime,batchNumber,id,dealerList,areaList,preSetNumber} = this.editForm;
      // console.log(this.editForm)
      let data={
        "id": id,
        "preSetNumber": preSetNumber,
        "area_Id":areaList,
        "productId":productList,
        "dealer_Id":dealerList,
        "send_Time": sendTime,
        "batchNumber": batchNumber
      }
      // console.log(data)
      // return
      DeliveryPlanUpdate(data)
      .then(res=>{
        console.log("修改成功")
        this.dialogVisible2 = false;
        let data = {
          "pageIndex": 1,
          "pageSize": this.PageSize,
          "keywords": this.keyword,
        }
        DeliveryPlanFindPageData(data)
        .then(res=>{
          this.tableData = res.data;
          this.PageSize = res.PageSize;
          this.page = 1;
          this.dataCount = res.dataCount;
        })
        .catch(err=>{
          console.log(err);
        })
      })
      .catch(err=>{
        console.log("修改失败")
      })
    },
    // 启用/停用
    setValid(row){
      let IsValid;
      if(row.IsValid === 1){
        this.$confirm('确定需要停用吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '确定停用!',
          });
          IsValid= 0;
          // console.log(IsValid)
          let params = {
            id: row.Id,
            valid: IsValid
          }
          DeliveryPlanSetValid(params)
          .then(res=>{
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            DeliveryPlanFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
      }else if(row.IsValid === 0){
        this.$confirm('确定需要启用吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$message({
            type: 'success',
            message: '确定启用!',
          });
          IsValid= 1;
          let params = {
            id: row.Id,
            valid: IsValid
          }
          DeliveryPlanSetValid(params)
          .then(res=>{
            let data = {
              "pageIndex": 1,
              "pageSize": this.PageSize,
              "keywords": this.keyword,
            }
            DeliveryPlanFindPageData(data)
            .then(res=>{
              this.tableData = res.data;
              this.PageSize = res.PageSize;
              this.page = 1;
              this.dataCount = res.dataCount;
            })
            .catch(err=>{
              console.log(err);
            })
          })
          .catch(err=>{
            console.log(err)
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
        // IsValid = 0
      }
    },
    // 点击删除
    deleteBtn(row){
      this.$confirm('确定需要删除吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!',
        });
        let params={
          id: row.Id,
          isDeleted: 1,
        }
        // console.log(params)
        // return
        DeliveryPlanSetDelete(params)
        .then(res=>{
          let data = {
            "pageIndex": 1,
            "pageSize": this.PageSize,
            "keywords": this.keyword,
          }
          DeliveryPlanFindPageData(data)
          .then(res=>{
            this.tableData = res.data;
            this.PageSize = res.PageSize;
            this.page = 1;
            this.dataCount = res.dataCount;
          })
          .catch(err=>{
            console.log(err);
          })
        })
        .catch(err=>{
          console.log(err)
        })  
      }).catch(()=>{
        this.$message({
          type: 'info',
          message: '已取消'
        });  
      })
    },
        
    handleClose(done) {
      done();
      if (this.$refs.addForm !== undefined)
      {this.$refs.addForm.resetFields()}
      if (this.$refs.editForm !== undefined)
      {this.$refs.editForm.resetFields()}
    },
  }

};
</script>
<style>

.Form-btn{
  /* background: red; */
  width: 100%;
  border-top: 1px solid #ddd;
  text-align: right;
  padding: 20px 20px 0 0;
}

</style>