/** 左边菜单栏 */

<template>
  <el-menu :default-active="$route.name" class="el-menu-vertical-demo leftnavw"
  @select="handleSelect" router @open="handleOpen" @close="handleClose"
  background-color="#4A4E4E" text-color="#fff" active-text-color="#fe5157">
    <div class="logobox">
      <!-- <img class="logoimg" src="../assets/img/logo.png" alt=""> -->
      <span>后台管理</span>
    </div>
    <el-menu-item index="Home" :router="{path: '/index/Home'}">
        <i class="iconfont icon-shouye"></i>
        <span slot="title">首页</span>
    </el-menu-item>
    <el-menu-item index="CreateCode"  :router="{path: '/index/CreateCode'}">
        <i class="iconfont icon-erweima"></i>
        <span slot="title">二维码生成</span>
    </el-menu-item>
    <el-menu-item index="RelationInOut" :router="{path: '/index/RelationInOut'}">
        <i class="iconfont icon-neimawm"></i>
        <span slot="title">内外码关联回传</span>
    </el-menu-item>
    <el-menu-item index="RelationOutBox" :router="{path: '/index/RelationOutBox'}">
        <i class="iconfont icon-waimaxm"></i>
        <span slot="title">外码箱码关联回传</span>
    </el-menu-item>
    <el-menu-item index="RelationPileBox" :router="{path: '/index/RelationPileBox'}">
        <i class="iconfont icon-xiangmadm"></i>
        <span slot="title">跺码箱码关联回传</span>
    </el-menu-item>
    
    <!-- <el-menu-item index="Recharge" :router="{path: '/index/Recharge'}">
        <i class="iconfont icon-guizeguanli"></i>
        <span slot="title">充值</span>
    </el-menu-item> -->
    <el-menu-item index="EcodeSearch" :router="{path: '/index/EcodeSearch'}">
        <i class="iconfont icon-chaxun"></i>
        <span slot="title">E码查询</span>
    </el-menu-item>
    <el-menu-item index="invoice" :router="{path: '/index/invoice'}">
        <i class="iconfont icon-guizeguanli"></i>
        <span slot="title">发货单</span>
    </el-menu-item>
    <el-submenu index="">
        <template slot="title">
            <i class="iconfont icon-xitongmse"></i>
            <span>系统管理</span>
        </template>
        <el-menu-item-group>
            <el-menu-item index="ProductGroup" :router="{path: '/index/ProductGroup'}">
              <i class="iconfont icon-chanpin"></i>
              <span>产品组</span>
            </el-menu-item>
            <el-menu-item index="PrizeActivity" :router="{path: '/index/PrizeActivity'}">
              <i class="iconfont icon-zhongjiangjilu"></i>
              <span slot="title">抽奖活动</span>
            </el-menu-item>
            <el-menu-item index="PrizeRule" :router="{path: '/index/PrizeRule'}">
              <i class="iconfont icon-guizeguanli"></i>
              <span slot="title">中奖规则</span>
            </el-menu-item>
            <el-menu-item index="CustomerManager" :router="{path: '/index/CustomerManager'}">
              <i class="iconfont icon-kehumse"></i>
              <span>客户管理</span>
            </el-menu-item>
            <el-menu-item index="UserManager" :router="{path: '/index/UserManager'}">
              <i class="iconfont icon-user"></i>
              <span>用户管理</span>
            </el-menu-item>
            <el-menu-item index="RoleManager" :router="{path: '/index/RoleManager'}">
              <i class="iconfont icon-juesemse"></i>
              <span>角色管理</span>
            </el-menu-item>
            <el-menu-item index="AuditLog" :router="{path: '/index/AuditLog'}">
              <i class="iconfont icon-shenjirizhi"></i>
              <span>审计日志</span>
            </el-menu-item>
            <el-menu-item index="Menu" :router="{path: '/index/Menu'}">
              <i class="iconfont icon-caidanmse"></i>
              <span>菜单管理</span>
            </el-menu-item>
        </el-menu-item-group>
    </el-submenu>
    <!-- <el-menu-item :index="item.NavigateUrl" v-for="item in MenuItem" :key="item.id" :router="{path: '/index/' + item.NavigateUrl}">
      <i class="iconfont" :class="'icon-' + item.NavigateUrl" ></i>
      <span>{{item.FullName}}</span>
    </el-menu-item>
    <el-submenu index="">
      <template slot="title">
        <i class="iconfont icon-xitongmse"></i>
        <span>系统管理</span>
      </template>
      <el-menu-item-group>
        <el-menu-item :index="item.NavigateUrl" v-for="item in MenuGroup" :key="item.id" :router="{path: '/index/' + item.NavigateUrl}">
          <i class="iconfont" :class="'icon-' + item.NavigateUrl" ></i>
          <span>{{item.FullName}}</span>
        </el-menu-item>
      </el-menu-item-group>
    </el-submenu> -->
  </el-menu>
</template>

<script>
  import { GetMenuTree } from '@/api/login';
  export default {
    name: 'leftnav',
    data(){
      return{
        activeIndex: '',
        MenuItem:[],
        ParentId: 'd9df77ccbadc445389da53cae1199fef',
        MenuGroup:[],
      }
    },
    watch:{
      $route(){
        this.handleSelect(this.activeIndex)
      }
    },
    mounted(){
      // GetMenuTree()
      // .then(res=>{
      //   console.log(res);
      //   for(var i=0;i<res.length;i++){
      //     // console.log(res[i].ParentId)
      //     if(res[i].ParentId == this.ParentId){
      //       this.MenuGroup.push(res[i]);
      //     }else{
      //       this.MenuItem.push(res[i]);
      //     }
      //   }
      //   console.log(this.MenuItem,this.MenuGroup);
      // })
      // .catch(err=>{
      //   console.log(err)
      // })
    },
    methods: {
      handleOpen(key, keyPath) {
        console.log(key, keyPath);
      },
      handleClose(key, keyPath) {
        console.log(key, keyPath);
      },
      handleSelect(index){
        this.activeIndex = index;
      }
    },
    created() {
      
    }
  }
</script>
<style>
.logobox{
  height: 60px;
  text-align: center;
  font-size: 18px;
  color: #fff;
  line-height: 60px;
}
.leftnavw{
  width: 100%;
  height: 100%;
  min-height: 400px;
  border: none;
  text-align: left;
}
</style>