<template>
  <el-container class="index-con">
    <el-aside :class="showclass">
      <leftnav></leftnav>
    </el-aside>
    <el-container class="main-con">
      <el-header class="index-header">
        <headnav></headnav>
      </el-header>
      <el-main clss="index-main">
        <router-view>
        </router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
// 导入组件
import headnav from '../components/headnav.vue'
import leftnav from '../components/leftnav.vue'
export default {
  name: 'index',
  data() {
    return {
      showclass: 'asideshow',
      showtype: false
    }
  },
  // 注册组件
  components: {
    headnav,
    leftnav,
  },
  methods: {},
  created() {
    // const token = localStorage.getItem('token');
  },
  beforeUpdate() {},
  // 挂载前状态(里面是操作)
  beforeMount() {
    // 弹出登录成功
    this.$message({
      message: '登录成功',
      type: 'success',
    });    
  }
}
</script>
<style >
.index-con {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.asideshow {
  width: 250px !important;
  height: 100%;
  background-color: #fff;
  margin: 0px;
}
.index-header,
.index-main {
  padding: 0px;
  /* border-left: 2px solid #c0c4cc; */
}
</style>
