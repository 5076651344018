import requset from "@/utils/requset";

// 二维码查询
export function FindPageCreateCodeData(data){
  return requset({
    url:'CreateCode/FindPageCreateCodeData',
    method:'post',
    data
  })
}

// 新增二维码
export function CreateCodeAdd(data){
  return requset({
    url:'CreateCode/Add',
    method:'post',
    data
  })
}

// 二维码下载
export function Download(params){
  return requset({
    url:'CreateCode/Download',
    method:'get',
    params
  })
}

// 内外码关联查询
export function OIFindPageModelData(data){
  return requset({
    url: '/UploadOutIn/FindPageModelData',
    method: 'post',
    data
  })
}

// 内外码模板下载
export function OIDowmload(params){
  return requset({
    url: '/UploadOutIn/Download',
    method: 'get',
    params
  })
}

// 外码箱码关联查询
export function OBFindPageModelData(data){
  return requset({
    url: '/UploadOutBox/FindPageModelData',
    method: 'post',
    data
  })
}

// 外码箱码模板下载
export function OBDowmload(params){
  return requset({
    url: '/UploadOutBox/Download',
    method: 'get',
    params
  })
}

// 箱码跺码关联查询
export function PBFindPageModelData(data){
  return requset({
    url: '/UploadPileBox/FindPageModelData',
    method: 'post',
    data
  })
}

// 箱码跺码模板下载
export function PBDowmload(params){
  return requset({
    url: '/UploadPileBox/Download',
    method: 'get',
    params
  })
}

 // 发货计划查询
 export function DeliveryPlanFindPageData(data){
  return requset({
    url: '/DeliveryPlan/FindPageData',
    method: 'post',
    data
  })
}

 // 新建发货计划
 export function DeliveryPlanAdd(data){
  return requset({
    url: '/DeliveryPlan/Add',
    method: 'post',
    data
  })
} // 修改发货计划
export function DeliveryPlanUpdate(data){
 return requset({
   url: '/DeliveryPlan/Update',
   method: 'post',
   data
 })
} // 发货计划的 启用/停用 状态
export function DeliveryPlanSetValid(params){
 return requset({
   url: '/DeliveryPlan/SetValid',
   method: 'post',
   params
 })
} // 删除发货计划
export function DeliveryPlanSetDelete(params){
 return requset({
   url: '/DeliveryPlan/SetDelete',
   method: 'post',
   params
 })
}