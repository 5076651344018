/**
    客户管理
 */


<template>
  <div class="stbox">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>客户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询角色名称 -->
    <el-form class="user-search">
      <el-form-item label="客户类型：">
        <el-select size="small" class="formwid" v-model="op" placeholder="请选择">
          <el-option value="新华"></el-option>
          <el-option value="古越"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="关键字：">
        <el-input type="text" v-model="text" size="small" placeholder="请输入客户名称/编号"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search">查询</el-button>
      </el-form-item>
    </el-form>
    <!--列表 客户管理列表 -->
    <div class="customerbox">
      <div class="boxleft">
        <el-row class="plusdata">
          <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible = true">新增</el-button>
        </el-row>
        <el-table :data="tableData"  highlight-current-row  border height="610" style="width: 100%;">
          <el-table-column fixed align="center" type="index" width="60" label="#">
          </el-table-column>
          <el-table-column fixed prop="roleName" label="产品名称" >
          </el-table-column>
          <el-table-column fixed prop="roleNo" label="产品编号" >
          </el-table-column>
          <el-table-column  prop="address" label="客户" >
          </el-table-column>
          <el-table-column prop="state" label="状态">
            <template slot-scope="scope">
              <el-popover>
                <div slot="reference" class="name-wrapper">
                  <el-tag size="medium">{{ scope.row.state }}</el-tag>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" width="240">
              <el-button size="mini" @click="dialogVisible = true">修改</el-button>
              <el-button size="mini" type="warning" >停用</el-button>
              <el-button size="mini" type="danger " @click="menuAccess(scope.$index, scope.row)">删除</el-button>
          </el-table-column>
        </el-table>
        <!-- 分页器 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage4"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="10"
          layout="total, sizes, prev, pager, next, jumper"
          :total="40"
          background>
        </el-pagination>
      </div>
      <div class="boxright">
        <el-tabs v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="账号" name="first">
            <el-table border height="610">
              <el-table-column fixed prop="roleName" label="账号" >
              </el-table-column>
              <el-table-column fixed prop="roleNo" label="姓名" >
              </el-table-column>
              <el-table-column  prop="address" label="手机号" >
              </el-table-column>
              <el-table-column  prop="address" label="电子邮件" >
              </el-table-column>
              <el-table-column  prop="address" label="状态  " >
              </el-table-column>
            </el-table>
          </el-tab-pane>
          <el-tab-pane label="中奖列表" name="second">配置管理</el-tab-pane>
          <el-tab-pane label="操作日志" name="third">
            <el-form>
              <div class="timese">
                <el-form-item style="width:400px;" label="操作时间">
                  <el-date-picker style="width:300px;" size="small" v-model="value1" type="datetimerange" start-placeholder="开始日期" end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']">></el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button size="small" type="primary" icon="el-icon-search">查询</el-button>
                </el-form-item>
              </div>
              <el-table border height="560">
                <el-table-column fixed prop="roleName" label="账号" >
                </el-table-column>
                <el-table-column fixed prop="roleNo" label="姓名" >
                </el-table-column>
                <el-table-column  prop="address" label="手机号" >
                </el-table-column>
                <el-table-column  prop="address" label="电子邮件" >
                </el-table-column>
                <el-table-column  prop="address" label="状态  " >
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="currentPage4"
                :page-sizes="[10, 20, 30, 40]"
                :page-size="10"
                layout="total, sizes, prev, pager, next"
                :total="40"
                background>
              </el-pagination>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    
    <!-- 点击修改，弹出表单 -->
    <el-dialog title="修改" width="760px" :visible.sync="dialogVisible" :before-close="handleClose">
      <el-form ref="editForm" :model="editForm" class="editForm" label-width="100px" @submit.native.prevent>
        <el-form-item label="产品编号：">
          <el-input class="formwid" v-model="roleName" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品名称：">
          <el-input class="formwid" v-model="roleNo" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="客户：">
          <el-select class="formwid" v-model="op" placeholder="请选择">
            <el-option value="新华"></el-option>
            <el-option value="古越"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "productgroup",
  data() {
    return{
      op: '',
      text:'',
      index: '',
      roleName: '',
      roleNo:'',
      value1:'',
      activeName: 'first',
      currentPage4: 1,
      tableData: [{
        roleNo: '34534631451',
        date: '2016-05-02',
        roleName: '会稽山',
        address: '上海市普陀区金沙江路 1518 弄',
        rules: '内码',
        state: '启用'
      },{
        roleNo: '31463145133',
        date: '2016-05-01',
        roleName: '新华只改',
        address: '上海市普陀区金沙江路 1519 弄',
        rules: '内码',
        state: '启用'
      }, {
        roleNo: '1236443513454',
        date: '2016-05-03',
        roleName: '鲜花之啊哟',
        address: '上海市普陀区金沙江路 1516 弄',
        rules: '内码',
        state: '未启用'
      },],
      editForm: {
        roleName: '',
        roleNo:'',
        editTime:'',
      },
      dialogVisible: false,
    }
  },
  methods: {
    cons(index){
      console.log(this.tableData[0].state);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    handleClick(tab, event) {
      console.log(tab, event);
    }
  }
}
</script>

<style>
.customerbox{
  display: flex;
}
.boxleft, .boxright{
  width: 50%;
}
.boxleft{
  padding-top: 8px;
}
.plusdata{
  margin-bottom: 15px;
}
.boxright{
  padding: 0 10px;
}
/* .timese{
  display: flex;
} */
.timese .el-form-item{
  display: inline-block;
  margin-bottom: 9px;
}
</style>