/**
* 菜单管理
*/ 

<template>
  <!-- 组件主盒子 -->
  <div class="stbox">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index/Menu' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询 -->
    <el-form class="user-search" ref="FindCreateFrom" :model="FindCreateFrom">
      <el-form-item label="关键字：" prop="batchNumber">
        <el-input type="text" v-model="FindCreateFrom.batchNumber" size="small" placeholder="批次号" @change="CreatCodeBatchNumber"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search" @click="findCodeData">查询</el-button>
      </el-form-item>
    </el-form>
    <!--表格展示 + 点击新增数据 -->
    <el-row class="plusdata marbottom22">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible = true">新增</el-button>
    </el-row>
    <el-table class="marbottom22" :data="MuenDate" height="620" style="width: 100%; " :default-sort = "{prop: 'date', order: 'descending'}" stripe border
    >
      <el-table-column align="center" type="index" width="60" label="#"></el-table-column>
      <el-table-column prop="Id" label="菜单编号" width="140"></el-table-column>
      <el-table-column prop="CreateNumber" label="父级菜单编号"></el-table-column>
      <el-table-column prop="CodeType" label="菜单名称" width="100">
        <template>

        </template>
      </el-table-column>
      <el-table-column prop="CustomerName" label="前端路由url" width="140"></el-table-column>
      <el-table-column prop="ProductName" label="排序"></el-table-column>
      <el-table-column prop="Status" label="状态" width="120" align="center">
        <template slot-scope="{row}">
          <el-button v-if="row.Status === 1" size="mini" :disabled="true" type="primary">生成中</el-button>
          <el-button v-if="row.Status === 2" size="mini" :disabled="false" type="success" @click="downCreatCode(row.Id)">下载</el-button>
          <el-button v-if="row.Status === 3" size="mini" :disabled="true" type="info" >已下载</el-button>
          <el-button v-if="row.Status === -1" size="mini" :disabled="true" type="danger">生成失败</el-button>
        </template>
      </el-table-column>
      <el-table-column align="center" label="操作" width="260">
        <div class="caozuo">
          <el-button size="mini" @click="dialogVisible = true">修改</el-button>
          <el-popconfirm title="确定要停用吗？">
            <el-button slot="reference" size="mini" type="warning" >停用</el-button>
          </el-popconfirm>
          <el-popconfirm title="确定要删除吗">
            <el-button slot="reference" size="mini" type="danger ">删除</el-button>
          </el-popconfirm>
          <el-button size="mini" type="success" @click="dialogVisible2 = true">授权</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination 
      :current-page= page
      :page-sizes="[10, 20, 30, 40]"
      :page-size= PageSize
      layout="total, prev, pager, next, sizes"
      :total= dataCount
      background
      @size-change="sizeChange"
      @current-change="currentChange">
    </el-pagination>

    <!-- 点击添加 显示添加表单 -->
    <el-dialog title="新增" width="760px" :visible.sync="dialogVisible" :before-close="handleClose">
      <el-form ref="addForm" :model="addForm" class="addForm" label-width="100px" @submit.native.prevent :rules="rules">
       <el-form-item label="产品：" prop="productId">
        <el-select class="formwid" v-model="addForm.productId" placeholder="请选择产品" clearable>
          <el-option label="石库门上海老酒红标9年" value="106CF777CD254B82BFA83892A5E1DFA5"></el-option>
          <el-option label="新华测试盖" value="新华测试盖"></el-option>
        </el-select>
       </el-form-item>
       <el-form-item label="生成规则：" prop="codeType">
        <el-select class="formwid" v-model="addForm.codeType" placeholder="请选择类型" clearable>
          <el-option label="内码" value="1"></el-option>
          <el-option label="外码" value="2"></el-option>
        </el-select>
       </el-form-item>
       <el-form-item label="生成数量：" prop="createNumber">
         <el-input class="formwid" v-model="addForm.createNumber"></el-input>
       </el-form-item>
       <el-form-item label="备注：" prop="remark">
         <el-input class="formwid" v-model="addForm.remark"></el-input>
       </el-form-item>
       <div class="Form-btn">
        <el-button type="primary" @click="submitForm('addForm')">确 定</el-button>
        <el-button @click="resetForm('addForm')">重 置</el-button>
       </div>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { FindPageCreateCodeData,Download,CreateCodeAdd } from '@/api/axios';


export default {
  name: "createcode",
  filters:{
    resolvingDate(date){
    //date是传入的时间
      let d = new Date(date);

      let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
      let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
      let hours = d.getHours()<10 ? '0'+d.getHours() : d.getHours();
      let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
      let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();

      let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;

      return times
    }
  },
  data() {
    const validatorNum = (rules,value,callback) =>{
      // if (value === ''){
      //   callback(new Error('数量不能为空，最小数量为10000'));
      // }else{
      //   if(value%10000 !== 0){
      //     callback(new Error('请输入10000的整数倍'));
      //   }else if(value > 1000000){
      //     callback(new Error('最大生成数量为1000000'));
      //   }
      //   callback();
      // }
    };
    return {
      MuenDate: [],
      PageSize: 0,
      page: 0,
      dataCount: 0,
      NoError:'',
      dialogVisible: false,
      FindCreateFrom:{
        batchNumber: '',
        customerId: '',
        value1: '',
        beginDate:'',
        endDate:'',
      },
      addForm: {
        createNumber: '',
        remark: '',
        productId:'',
        codeType: '',
        downUserId: '',
        downUserName: '',
      },
      rules: {
        createNumber:[{required: true,validator: validatorNum, trigger: 'change'}],
        productId: [{required: true, message: '请选择产品',trigger: 'change'}],
        codeType: [{required: true, message: '请选择类型',trigger: 'change'}],
      }
    }
  },
  mounted(){
    this.downUserId= Cookies.get('CustomerName') || '管理员';
    this.downUserName = Cookies.get('DspName') || '';
    
  },
  // 里面的函数只有调用才会执行
  methods: {

    // 分页赋值
    sizeChange(val) {
      // console.log(val,this.PageSize,this.page)
      let data = {
        "pageIndex": this.page,
        "pageSize": val,
      }
      FindPageCreateCodeData(data)
      .then(res=>{
        console.log(res,'---------1----------')
        this.CreateCodeData = res.data;
        this.PageSize = res.PageSize;
        this.page = res.page;
        console.log(this.page)
      })
      .catch(err=>{
        console.log(err)
      })
    },
    currentChange(val){
      console.log(val);
      let data = {
        "pageIndex": val,
        "pageSize": this.PageSize,
      }
      console.log(data);
      FindPageCreateCodeData(data)
      .then(res=>{
        console.log(res,'---------2----------',res.PageSize);
        this.CreateCodeData = res.data;
        this.PageSize = res.PageSize;
        this.page = val;
        console.log(this.page)
      })
      .catch(err=>{
        console.log(err);
      })
    },
    // 获取查询时间范围
    CreatCodeTime(time){
      if(time !=null){
        this.beginDate = time[0];
        this.endDate = time[1];
      }else{
        this.beginDate = '';
        this.endDate = '';
      }
    },
    CreatCodeCustomerId(val){
      this.customerId = val;
    },
    CreatCodeBatchNumber(val){
      this.batchNumber = val;
    },
    // 查询生码
    findCodeData(){
      let data = {
        "batchNumber": this.batchNumber,
        "customerId": this.customerId,
        "beginDate": this.beginDate,
        "endDate": this.endDate,
      }
      FindPageCreateCodeData(data)
      .then(res=>{
        this.CreateCodeData = res.data;
        for(var i =0;i < this.CreateCodeData.length;i++){
          // console.log(this.CreateCodeData[i].Status);
          if(this.CreateCodeData[i].CodeType == 1){
            this.CreateCodeData[i].CodeType = "内码";
          }else if(this.CreateCodeData[i].CodeType == 2){
            this.CreateCodeData[i].CodeType = "外码";
          }else if(this.CreateCodeData[i].CodeType == 3){
            this.CreateCodeData[i].CodeType = "箱码";
          }else if(this.CreateCodeData[i].CodeType == 4){
            this.CreateCodeData[i].CodeType = "跺码";
          }else{
            this.CreateCodeData[i].CodeType = "";
          };
        };
        this.PageSize = res.PageSize;
        this.page = res.page;
        this.dataCount = res.dataCount;
      })
      .catch(err=>{
        console.log(err);
      })
    },
    // 新增生码
    submitForm(addForm){
      // console.log(this.addForm,this.dialogVisible);
      const {createNumber,remark,codeType,productId} = this.addForm;
      let data = {
        "codeType": Number(codeType),
        "productId": productId,
        "createNumber": Number(createNumber),
        "remark": remark,
        "downUserId": "admin",
        "downUserName": "admin",
      }
      console.log(data);
      // return;
      CreateCodeAdd(data)
      .then(res=>{
        console.log(res);
        this.dialogVisible = false;
        findCodeData()
      })
      .catch(err=>{
        console.log(err)
      })
    },
    // 下载文件
    downCreatCode(data){
      let params={
        fileName: data
      }
      Download(params)
      .then(res=>{
        const element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res))
        element.setAttribute('download', data);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
      })
      .catch(err=>{
        console.log(err)
      })
    },
    
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
          this.$refs.addForm.resetFields();
        })
        .catch(_ => {});
    },
  },

};
</script>
<style>

.Form-btn{
  /* background: red; */
  width: 100%;
  border-top: 1px solid #ddd;
  text-align: right;
  padding: 20px 20px 0 0;
}

</style>