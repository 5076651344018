/**
    用户管理
 */


<template>
  <div class="stbox">
    <!-- 面包屑导航 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/index/Home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>用户管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 查询角色名称 -->
    <el-form class="user-search">
      <el-form-item label="关键字：">
        <el-input type="text" v-model="text" size="small" placeholder=""></el-input>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" icon="el-icon-search">查询</el-button>
      </el-form-item>
    </el-form>
    <!--列表-->
    <el-row class="plusdata">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="dialogVisible = true">新增</el-button>
    </el-row>
    <el-table :data="tableData"  highlight-current-row  border  style="width: 100%;height:620px;">
      <el-table-column align="center" type="index" width="60" label="#">
      </el-table-column>
      <el-table-column  prop="roleName" label="产品名称" >
      </el-table-column>
      <el-table-column  prop="roleNo" label="产品编号" >
      </el-table-column>
      <el-table-column  prop="editTime" label="客户" >
      </el-table-column>
      <el-table-column prop="state" label="状态">
      </el-table-column>
      <el-table-column align="center" label="操作" width="260">
        <div class="caozuo">
          <el-button size="mini" @click="dialogVisible = true">修改</el-button>
          <el-popconfirm title="确定要停用吗？">
            <el-button slot="reference" size="mini" type="warning" >停用</el-button>
          </el-popconfirm>
          <el-popconfirm title="确定要删除吗">
            <el-button slot="reference" size="mini" type="danger ">删除</el-button>
          </el-popconfirm>
          <el-button size="mini" type="success" @click="dialogVisible2 = true">授权</el-button>
        </div>
      </el-table-column>
    </el-table>
    <!-- 分页器 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage4"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="10"
      layout="total, sizes, prev, pager, next, jumper"
      :total="40"
      background>
    </el-pagination>
    <!-- 点击修改，弹出表单 -->
    <el-dialog title="修改" width="760px" :visible.sync="dialogVisible" :before-close="handleClose">
      <el-form ref="editForm" :model="editForm" class="editForm" label-width="100px" @submit.native.prevent>
        <el-form-item label="产品编号：">
          <el-input class="formwid" v-model="roleName" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="产品名称：">
          <el-input class="formwid" v-model="roleNo" placeholder="请输入产品名称"></el-input>
        </el-form-item>
        <el-form-item label="客户：">
          <el-select class="formwid" v-model="op" placeholder="请选择">
            <el-option value="新华"></el-option>
            <el-option value="古越"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="saveForm">保 存</el-button>
        <el-button @click="dialogVisible = false">取 消</el-button>
      </div>
    </el-dialog>
    <!-- 点击授权，弹出授权穿梭框 -->
    <el-dialog title="授权" width="760px" :visible.sync="dialogVisible2" :before-close="handleClose">
      <div style="text-align: center">
      <el-transfer style="text-align:left;display:inline-block;" :titles="['角色列表','已选角色']" v-model="authorization" :data="data"></el-transfer>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "productgroup",
  data() {
    const generateData = _ => {
      const data = [];
      for (let i = 1; i <= 15; i++) {
        data.push({
          key: i,
          label: `备选项 ${ i }`,
          disabled: i % 4 === 0
        });
      }
      return data;
    };
    return{
      data: generateData(),
      authorization: [1, 4],
      op: '',
      text:'',
      index: '',
      roleName: '',
      roleNo:'',
      currentPage4: 1,
      tableData: [{
        roleNo: '34534631451',
        date: '2016-05-02',
        roleName: '会稽山',
        address: '上海市普陀区金沙江路 1518 弄',
        rules: '内码',
        state: '启用'
      }, {
        roleNo: '134534534513452',
        date: '2016-05-04',
        roleName: '古越龙山',
        address: '上海市普陀区金沙江路 1517 弄',
        rules: '内码',
        state: '未启用'
      }, {
        roleNo: '31463145133',
        date: '2016-05-01',
        roleName: '新华只改',
        address: '上海市普陀区金沙江路 1519 弄',
        rules: '内码',
        state: '启用'
      }, {
        roleNo: '1236443513454',
        date: '2016-05-03',
        roleName: '鲜花之啊哟',
        address: '上海市普陀区金沙江路 1516 弄',
        rules: '内码',
        state: '未启用'
      },],
      editForm: {
        roleName: '',
        roleNo:'',
        editTime:'',
      },
      dialogVisible: false,
      dialogVisible2: false,
    }
  },
  methods: {
    cons(index){
      console.log(this.tableData[0].state);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.currentPage4 = val;
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    saveForm(){
      this.dialogVisible = false;
      
    }
  }
}
</script>
<style>
.caozuo{
  display: flex;
  justify-content: space-around;
}
</style>