<template>
  <div class="login-wrap">
    <el-form label-position="left" :model="loginForm" ref="loginForm" label-width="0px" class="demo-loginForm login-container" @keyup.enter.native="login">
      <h3 class="title">用户登录</h3>
      <el-form-item prop="username">
        <el-input type="text" v-model="loginForm.username" auto-complete="off" placeholder="账号"></el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input type="password" v-model="loginForm.password" auto-complete="off" placeholder="密码"></el-input>
      </el-form-item>
      <!-- <el-checkbox class="remember" v-model="rememberpwd">记住密码</el-checkbox> -->
      <el-form-item style="width:100%;">
        <el-button type="primary" style="width:100%;" @click="login('loginForm')">登录</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script type="text/ecmascript-6">
import axios from 'axios'
import Cookies from 'js-cookie'
import { GetApiToken, FindByAccount,GetCommonRoleByUserId,GetMenuByRoleId,GetMenuTree } from '@/api/login';
export default {
  name: 'login',
  data() {
    return {
      // 记住密码
      rememberpwd: false,
      loginForm: {
        //username和password默认为空
        username: '',
        password: '',
      },
    }
  },
  // 创建完毕状态(里面是操作)
  created() {    
  },
  // 里面的函数只有调用才会执行
  methods: {
    login(){
      const { username, password } = this.loginForm;
      // 判断账号密码是否为空，没有则弹出弹框
      if(username === '' ||password === ''){
        alert("账号或密码不能为空");
      }else{
        let params = {
          account:username,
          passWord:password,
        }
        GetApiToken(params)
        .then(res=>{
          const millisecond = new Date().getTime();
          const expiresTime = new Date(millisecond + 60 * 1000 * 30);
          Cookies.set('token',res.token,{expires:expiresTime});
          FindByAccount({
            account: username,
          })
          .then(({CustomerName,DspName,money,expiry_date,Id})=>{
            Cookies.set('CustomerName',CustomerName,{expires: expiresTime});
            Cookies.set('DspName',DspName,{expires: expiresTime});
            Cookies.set('money',money,{expires: expiresTime});
            Cookies.set('expiry_date',expiry_date,{expires: expiresTime});
            Cookies.set('userId',Id,{expires: expiresTime});
            // GetMenuTree(params)
            // .then(res=>{
            //   const MeunTree = res;
            //   console.log(MeunTree)
            // })
            // .catch(err=>{
            //   console.log(err)
            // })
            // return;
            this.$router.push({path: '/index'});
          })
          .catch(err=>{
            console.log(err)
            alert("账号密码错误！！");
          })
        })
        .catch(err=>{
          alert("服务器无反应，请联系管理员！！");
        })
      }
    },
  }
}
</script>

<style>
.login-wrap {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding-top: 10%;
  background-color: #112346;
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 100%;
}
.login-container {
  border-radius: 10px;
  margin: 0px auto;
  width: 350px;
  padding: 30px 35px 15px 35px;
  background: #fff;
  border: 1px solid #eaeaea;
  text-align: left;
  box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
}
.title {
  margin: 0px auto 40px auto;
  text-align: center;
  color: #505458;
}
.remember {
  margin: 0px 0px 35px 0px;
}
.code-box {
  text-align: right;
}
.codeimg {
  height: 40px;
}
</style>